import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function useScrollToHash() {
  const location = useLocation();
  const [navbarHeight, setNavbarHeight] = useState(0);

  useEffect(() => {
    const navbar = document.querySelector('.navbar');
    if (navbar) {
      setNavbarHeight(navbar.offsetHeight);
    }
  }, []);

  useEffect(() => {
    const hash = location.hash.substring(1);

    if (hash) {
      const targetElement = document.getElementById(hash);
      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop - navbarHeight,
          behavior: 'smooth',
        });
      } else {
        console.warn(`Element with ID "${hash}" not found.`);
      }
    }
  }, [location.hash, navbarHeight]); 
}

export default useScrollToHash;
