import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";

// const Container = tw.div`relative`;
const Container = tw(ContainerBase)`md:-my-20 -mx-8 px-8 z-30`;

const TextContent = tw.div`bg-gray-800 max-w-screen-xl mx-auto py-20 md:py-24 lg:py-8 text-center md:rounded-xl`;

const Heading = tw(SectionHeading)`text-white mt-4 font-black text-3xl sm:text-4xl lg:text-5xl text-center leading-tight`;
const Description = tw.p`mt-4 text-center text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-lg mx-auto px-4`
const Input = tw.input`mt-6 first:mt-0 border-b-2 pl-3 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500 rounded-lg`
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8 rounded-lg`

export default ({
  heading = <>Feel free to <span tw="text-primary-500">get in touch</span><wbr /> with us.</>,
  description = "",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container>
      <TextContent>
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <Form action={formAction} method={formMethod}>
          <Input type="email" name="email" placeholder="Your Email Address" />
          <Input type="text" name="name" placeholder="Full Name" />
          <Input type="text" name="subject" placeholder="Subject" />
          <Textarea name="message" placeholder="Your Message Here" />
          <SubmitButton type="submit">{submitButtonText}</SubmitButton>
        </Form>
      </TextContent>
    </Container>
  );
};
