import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";

import { ROUTES } from "./constants";
import AboutUsPage from "pages/AboutUs.js";
import CareersPage from "pages/Careers.js";
import Climate from "pages/Climate";
import ContactUsPage from "pages/ContactUs.js";
import EnergyEfficiency from "pages/EnergyEfficiency";
import RenewableEnergy from "pages/RenewableEnergy";
import ServiceLandingPage from "pages/Home.js";
import Research from "pages/Research";
import Resources from "pages/Resources";
import StubPage from "pages/StubPage";
import { useEffect } from "react";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Routers = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path={ROUTES.ABOUT} element={<AboutUsPage />} />
        <Route path={ROUTES.ENERGY_EFFICIENCY} element={<EnergyEfficiency />} />
        <Route path={ROUTES.RENEWABLE_ENERGY} element={<RenewableEnergy />} />
        <Route path={ROUTES.CLIMATE} element={<Climate />} />
        <Route path={ROUTES.RESEARCH} element={<Research />} />
        <Route path={ROUTES.RESOURCES} element={<Resources />} />
        <Route path={ROUTES.CONTACT_US} element={<ContactUsPage />} />
        <Route path={ROUTES.CAREERS} element={<CareersPage />} />
        <Route path={ROUTES.HOME} element={<ServiceLandingPage />} />
        <Route path="*" element={<StubPage />} />  {/* Catch-all route for unmatched paths */}
      </Routes>
    </Router>
  );
};

export default Routers;
