import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/Hero.js";
import FeatureStats from "components/features/FeatureStats.js";
import Footer from "components/footers/Footer.js";
import WhoWeAre from "components/features/WhoWeAre.js";

import announcement from "images/rudk/home/announcement.jpeg";
import BrickKiln from "images/rudk/brick_kiln.jpg";
import WhatWeOffer from "components/features/WhatWeOffer";
import WhyChooseUs from "components/features/WhyChooseUs";
import Portfolio from "components/features/Portfolio";
import PopularBlogs from "components/blogs/PopularBlogs";
import Industries from "components/features/Industries";
import Testimonials from "components/testimonials/Testimonials";
import AnimatedContainer from "components/misc/AnimatedContainer";
import { Modal } from "components/misc/Modal";
import styled from "styled-components";

const HighlightedText = tw.span`text-primary-500`

const ModalCard = tw.div`h-full flex! flex-col sm:border relative focus:outline-none`;
const ModalCardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-[100vw] h-[100vh] bg-cover bg-center rounded`
]);
const ModalTitle = tw.h3`text-2xl font-bold`;

export default () => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  const onModalClose = () => {
    setModalOpen(false);
  }

  return (
    <AnimationRevealPage disabled>
      <Modal isOpen={isModalOpen} onClose={onModalClose}>
          <ModalCard>
            <ModalCardImage imageSrc={announcement} />
          </ModalCard>
        </Modal>
      <Hero />
      <AnimatedContainer direction={"left"} initialOffsetX={"40%"}>
        <FeatureStats/>
      </AnimatedContainer>
      <WhoWeAre
        subheading={'Who we are'}
        heading={<>Leading Energy Efficiency Solution</>}
        description="Rastriya Urja Dakshata Kendra Pvt. Ltd. (RUDK), whose english translation is National Energy Efficiency Centre Pvt. Ltd., is driven by a team of visionary engineers dedicated to advancing energy efficiency and sustainable energy use. We aim to bridge the gap between the demand for energy and environmental sustainability without compromising on efficiency. Our commitment to quality, innovation, and mutual respect is reflected in our professional services that contribute to combating climate change. With a highly educated and experienced team, RUDK stands at the forefront of providing solutions that are not only cost-effective but also instrumental in reducing greenhouse gas emissions and creating jobs."
        buttonRounded={false}
        imageSrc={BrickKiln}
        primaryButtonText = "Learn more"
        primaryButtonUrl = "/about"
      />
      <WhatWeOffer 
        heading={<>Our <HighlightedText>Expertise</HighlightedText></>}
        subheading={''}
        description={<>Our expertise includes energy efficiency, energy management system, infrastructure upgrades, asset sustainability, renewable energy solutions and climate resilient enhancement and development. As one of the <HighlightedText>“Centre for Energy Efficiency”</HighlightedText> we aim to contribute to the attainment of energy and climate policy objectives.</>}
      />
      <WhyChooseUs/>
      <Industries />
      <Portfolio />
      <PopularBlogs />
      <Testimonials />
      {/* <Team />
      <FAQ
        heading={<>Any <HighlightedText>Questions ?</HighlightedText></>}
      /> */}
      <Footer />
    </AnimationRevealPage>
  );
}
