const ROUTES = {
  ABOUT: "/about",
  CAREERS: "/careers",
  CLIMATE_CHANGE: "/climate-change",
  CLIMATE: "/climate",
  CONTACT_US: "/contact-us",
  ENERGY_EFFICIENCY: "/energy-efficiency",
  HOME: "/",
  RENEWABLE_ENERGY: "/renewable-energy",
  RESEARCH: "/research",
  RESOURCES: "/resources",
}

const MENU_ROUTES = [
  {
    route: ROUTES.ABOUT,
    title: "About",
    subRoutes: [
      {
        name: "About us",
        sectionId: "aboutUs"
      },
      {
        name: "Vision",
        sectionId: "vision"
      },
      {
        name: "Values",
        sectionId: "values"
      },
      {
        name: "Team",
        sectionId: "team"
      }
    ]
  },
  {
    route: ROUTES.ENERGY_EFFICIENCY,
    title: "Energy Efficiency",
    subRoutes: [
      {
        name: "Energy Audit",
        sectionId: "energyAudit"
      },
      {
        name: "Fire and Safety Audit",
        sectionId: "fireAndSafety"
      },
      {
        name: "ESCO",
        sectionId: "esco"
      },
      {
        name: "Energy Management System",
        sectionId: "energyManagement"
      },
      {
        name: "Projects",
        sectionId: "projects"
      },
    ]
  },
  {
    route: ROUTES.RENEWABLE_ENERGY,
    title: "Renewable Energy",
  },
  {
    route: ROUTES.CLIMATE,
    title: "Climate",
  },
  {
    route: ROUTES.RESEARCH,
    title: "Research",
  },
  /* {
    route: ROUTES.RESOURCES,
    title: "Resources",
  }, */
  {
    route: ROUTES.CONTACT_US,
    title: "Contact Us",
  },
];

export { ROUTES, MENU_ROUTES };
