import React, { useEffect, useRef } from "react";
import tw from "twin.macro";
import { SectionHeading } from "components/misc/Headings.js";

import whyImage from "images/rudk/home/why-image.jpg";
import { stagger, useAnimate, useInView } from "framer-motion";

const Container = tw.div`relative my-16`;

const Heading = tw(SectionHeading)`w-full md:hidden`;


const Image = tw.img`hidden md:block md:w-full md:rounded-tr-2xl`

const Wrapper = tw.div`flex flex-col md:flex-row max-w-screen-xl mx-auto`;
const Col1 = tw.div`flex flex-col basis-1/5 grow`;
const Col2 = tw.div`basis-1/3 grow`;
const Col3 = tw.div`flex flex-col basis-2/5`;
const Row = tw.div`flex flex-row md:h-1/2`;
const RowTitle = tw(Row)`hidden md:flex justify-center items-center`;
const Item1 = tw(Row)`bg-gray-800 text-white md:rounded-tl-2xl`;
const Item2 = tw(Row)`bg-green-800 text-white md:rounded-bl-2xl`;
const Row3 = tw(Row)`flex-col md:flex-row`;
const Item3 = tw.div`flex basis-1/2 bg-gray-800 text-white`;
const Item4 = tw.div`flex basis-1/2 bg-green-800 text-white md:rounded-r-2xl`;
const Title = tw.p`text-4xl md:text-6xl text-left font-bold w-48`;
const TextContainer = tw.div`flex flex-col p-4 justify-center`
const OrderNumber = tw.p`text-3xl lg:text-5xl italic my-0`;
const Text = tw.p`text-lg lg:text-2xl`;

export default () => {
  const [scope, animate] = useAnimate();

  const isInView = useInView(scope);
  console.log('isInview', isInView);

  useEffect(() => {
    const animations = isInView
      ? [
          [
            "#whyChooseUsTitle",
            { transform: "scale(1)", opacity: 1, filter: "blur(0px)"},
            { type: "spring", duration: 0.8,}
          ],
          [
            "#item01",
            { transform: "scale(1)", opacity: 1, filter: "blur(0px)"},
            { type: "spring", duration: 0.6}
          ],
          [
            "#item02",
            { transform: "scale(1)", opacity: 1, filter: "blur(0px)"},
            { type: "spring", duration: 0.6}
          ],
          [
            "#item03",
            { transform: "scale(1)", opacity: 1, filter: "blur(0px)"},
            { type: "spring", duration: 0.6}
          ],
          [
            "#item04",
            { transform: "scale(1)", opacity: 1, filter: "blur(0px)"},
            { type: "spring", duration: 0.6}
          ],
        ]
      : [
          []
        ];

    animate([
      ...animations
    ]);
  }, [isInView]);

  const heading = "Why Choose Us?";
  return (
    <Container ref={scope}>
      <Heading id="whyChooseUsTitle">{heading}</Heading>
      <Wrapper>
        <Col1>
          <Item1 >
            <TextContainer id="item01">
              <OrderNumber>01</OrderNumber>
              <Text>Dedicated firm for conducting energy audit</Text>
            </TextContainer>
          </Item1>
          <Item2 >
            <TextContainer id="item02">
              <OrderNumber>02</OrderNumber>
              <Text>A team of qualified ISO 14001:500 certified auditer with decades of experience</Text>
            </TextContainer>
          </Item2>
        </Col1>
        <Col2><Image src={whyImage} alt={heading} /></Col2>
        <Col3>
          <RowTitle><Title id="whyChooseUsTitle">{heading}</Title></RowTitle>
          <Row3>
            <Item3>
              <TextContainer id="item03">
                <OrderNumber>03</OrderNumber>
                <Text>More than 30 energy audit conducted</Text>
              </TextContainer>
            </Item3>
            <Item4>
              <TextContainer id="item04">
                <OrderNumber>04</OrderNumber>
                <Text>One stop solution</Text>
              </TextContainer>
            </Item4>
          </Row3>
        </Col3>
      </Wrapper>
      {/* <Image src={whyChooseUs} alt="why choose us?" /> */}
    </Container>
  );
};
