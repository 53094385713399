import React from "react";
import tw from "twin.macro";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import HeaderBase from "components/headers/light.js"
import Footer from "components/footers/Footer.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Features from "components/features/SingleColImage.js";
import TeamDetails from "components/cards/TeamDetails.js";

import Quality from "images/rudk/about/quality.png";
import MutualRespect from "images/rudk/about/mutual-respect.png";
import Innovation from "images/rudk/about/innovation.png";
import Image2Src from "images/rudk/gas.jpg";
import sustainableGoal from "images/rudk/about/sustainable-goal.jpeg";
import coreValues from "images/rudk/about/core-values.png";

import { ROUTES } from "../constants";
import { NavLinksComponent } from "../helpers/NavLinks";
import useScrollToHash from "helpers/useScrollToHash";

const Header = tw(HeaderBase)`max-w-none`;

const HighlightedText = tw.span`text-primary-500`

const Subheading = tw.span`uppercase tracking-wider text-lg`;

const Section = tw.section``;

export default () => {
  useScrollToHash();

  return (
    <AnimationRevealPage disabled>
      <Header links={
        <NavLinksComponent
          activeLink={ROUTES.ABOUT}
        />
      } />
      <Section id="aboutUs">
        <MainFeature1
          subheading={<Subheading>About Us</Subheading>}
          heading="We are Energy Consulting Service."
          description="Rastriya Urja Dakshata Kendra Pvt. Ltd. (RUDK), whose english translation is National Energy Efficiency Centre Pvt. Ltd., is a preliminary step to the dream seen by the group of engineers to catalyze energy efficiency activities and who wanted to prove that people/nation did not need to compromise to the achievable of energy efficiency – that efficient use of energy can be instrumental towards reducing green house gas emissions, create jobs, meet growing energy demand being one of the fastest, cost-effective way to save money."
          buttonRounded={false}
          imageSrc={Image2Src}
        />
      </Section>
      <Section id="vision">
        <MainFeature1
          subheading={<Subheading>Our Vision</Subheading>}
          heading="We aim for energy efficiency and sustainable energy."
          description={<>With the goal of <HighlightedText>energy efficiency</HighlightedText> and the use of <HighlightedText>sustainable energy</HighlightedText> resources, to provide professional services that help combat <HighlightedText>climate change.</HighlightedText></>}
          buttonRounded={false}
          primaryButtonText="Contact Us"
          primaryButtonUrl="/contact-us"
          imageSrc={sustainableGoal}
          textOnLeft={false}
        />
      </Section>
      <Section id="values">
        <Features
          subheading={<Subheading>Our Values</Subheading>}
          heading="We follow these."
          description="We are guided by principles and fundamental beliefs that push us to fulfill our mission"
          imageSrc={coreValues}
          cards={[
            {
              imageSrc: Quality,
              title: "Quality and Efficiency",
              description: "We are distinguished by the quality of our work. Excellence is at the center of all our projects that we do which drives us forward."
            },
            {
              imageSrc: MutualRespect,
              title: "Mutual Respect",
              description: "We value mutual respect with all our clients & partners and within our team."
            },
            {
              imageSrc: Innovation,
              title: "Innovation",
              description: "We encourage innovative ideas across all our departments."
            },
          ]}
          linkText=""
        />
      </Section>
      <Section id="team">
        <TeamDetails
          subheading={<Subheading>Our Team</Subheading>}
        />
      </Section>
      <Footer />
    </AnimationRevealPage>
  );
};
