import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import HeaderBase from "components/headers/light.js"
import Footer from "components/footers/Footer.js";
import MainFeature1 from "components/features/TwoColWithButton.js";

import { NavLinksComponent } from "helpers/NavLinks";
import { ROUTES } from "../constants";
import backgroundImage from "images/rudk/energy-efficiency/hero-background.jpg";
import energyAudit from "images/rudk/energy-efficiency/energy-audit.jpeg";
import fireAndSafety from "images/rudk/energy-efficiency/fire-safety.jpeg";
import energyManagement from "images/rudk/energy-efficiency/energy-management.jpeg";
import esco from "images/rudk/energy-efficiency/esco.jpeg";
import useScrollToHash from "helpers/useScrollToHash";
import BackgroundAsImageWithCenteredContent from "components/hero/BackgroundAsImageWithCenteredContent";
import ProjectsTable from "components/tables/ProjectsTable";

const Header = tw(HeaderBase)`max-w-none`;
const Section = tw.section``;

const projects = [
  {
    "id": "1",
    "assignment": "Energy Audit Resedential and commercial buildings( Nepal Bank Limited, United Wires, Gorakha Department)",
    "location": "Chitwan, Itahari, Kathmandu",
    "client": "Alternative Energy Promotion Center"
  },
  {
    "id": "2",
    "assignment": "Energy audit at Bhawani Dairy and Foods",
    "location": "Chitwan",
    "client": "MADE Nepal"
  },
  {
    "id": "3",
    "assignment": "Energy Audit at Martin Dairy",
    "location": "Lalitpur",
    "client": "Asaman Nepal"
  },
  {
    "id": "4",
    "assignment": "Electrical Load Analysis at UN Building",
    "location": "Lalitpur",
    "client": "UNICEF"
  },
  {
    "id": "5",
    "assignment": "Fire and Electrical Safety Audit in Petroleum Depots of Nepal and Preparation of Standard Procedure for Safety Audit",
    "location": "Kathmandu",
    "client": "Nepal Oil Corporation Limited (NOC), Aviation Fuel Depot, Sinamangal (AFDS)"
  },
  {
    "id": "6",
    "assignment": "Energy Efficiency Audit in MSMEs (Production, Collection, and Processing) on Dairy Value Chain",
    "location": "Baglung and Makwanpur",
    "client": "Practical Action"
  },
  {
    "id": "7",
    "assignment": "Energy and resource efficiency audit at British Embassy Kathmandu",
    "location": "Kathmandu",
    "client": "British Embassy Nepal"
  },
  {
    "id": "8",
    "assignment": "Thermal and electrical audit at YAK Brewery",
    "location": "Chitwan",
    "client": "Yak Brewing Co"
  },
  {
    "id": "9",
    "assignment": "Energy Performance analysis of Mandakini Hotel Pvt. Ltd.",
    "location": "Kathmandu",
    "client": "Mandakini Hotel Pvt. Ltd"
  },
  {
    "id": "10",
    "assignment": "Detailed Energy Audit at National Health Care Pvt. Ltd",
    "location": "Simara, Bara",
    "client": "National Health Care Pvt. Ltd."
  },
  {
    "id": "11",
    "assignment": "Critical appliances Energy Audit at Bottlers Nepal Terai",
    "location": "Chitwan",
    "client": "Bottlers Nepal Terai"
  },
  {
    "id": "12",
    "assignment": "Building and Resource Efficiency Energy Audit at World Bank Group Office, Kathmandu",
    "location": "Durbar Marg, Kathmandu",
    "client": "World Bank Group Nepal"
  },
  {
    "id": "13",
    "assignment": "Energy Audit at S.K. Dairy Industry with collaboration with Swiss Contact, Nepalgunj",
    "location": "Nepalgunj",
    "client": "Swiss Contact"
  },
];

export default () => {
  useScrollToHash();

  return (
    <AnimationRevealPage disabled>
      <Header links={
        <NavLinksComponent
          activeLink={ROUTES.ENERGY_EFFICIENCY}
        />
      } />
      <Section id="overview">
        <BackgroundAsImageWithCenteredContent
          heading="Energy Efficiency"
          backgroundImage={backgroundImage}
          description="Energy efficiency is crucial for optimizing resource use and minimizing environmental impact. At Rastriya Urja Dakshata Kendra Pvt. Ltd., we are dedicated to helping businesses achieve energy efficiency and sustainability goals. Through comprehensive energy audits and fire safety audits, we identify areas for improvement and provide tailored solutions. Our advanced energy management systems enable real-time monitoring and control, leading to improved performance and continuous optimization. Additionally, our innovative ESCO (Energy Service Company) model allows us to share the benefits of energy savings directly with our clients, making energy efficiency investments financially attractive. We empower businesses to reduce energy consumption, cut costs, and contribute to a greener future."
          //old description="Energy efficiency is crucial for optimizing our resource use across various activities and sectors. By striving to achieve maximum results with minimal energy input, we not only reduce our energy consumption but also mitigate associated environmental impacts such as greenhouse gas emissions and resource depletion."
          //old description2=" Energy efficiency is at the core of our mission at Rastriya Urja Dakshata Kendra Pvt. Ltd. , where we are committed to revolutionizing how businesses and organizations approach their energy use. Our comprehensive suite of services is designed to optimize energy consumption, cut costs, and reduce environmental impact. Through our detailed energy audits, we pinpoint inefficiencies and offer actionable solutions tailored to your specific needs. Our fire safety audits ensure that your energy systems not only perform efficiently but also comply with safety standards. Implementing our advanced energy management systems allows for real-time monitoring and control of energy use, enhancing performance and facilitating continuous improvement. Additionally, our ESCO (Energy Service Company) solutions provide a unique model where we implement energy-saving measures and share the savings with you, ensuring that investments in efficiency translate directly into financial benefits. At Rastriya Urja Dakshata Kendra Pvt. Ltd., our goal is to empower you with the tools and expertise necessary to achieve a more efficient, safe, and sustainable energy footprint."
          hero={true}
        />
      </Section>
      <Section id="energyAudit">
        <MainFeature1
          // subheading={<Subheading>About Us</Subheading>}
          heading="Energy Audit"
          description="An energy audit is a comprehensive assessment of a building's energy consumption patterns. By meticulously examining your facility's energy use, we identify inefficiencies and areas for improvement. This analysis provides a clear roadmap for optimizing energy usage, reducing costs, and minimizing environmental impact. Our detailed report outlines actionable recommendations tailored to your specific needs, ensuring a more sustainable and cost-effective operation."
          buttonRounded={false}
          imageSrc={energyAudit}

        />
      </Section>
      <Section id="fireAndSafety">
        <BackgroundAsImageWithCenteredContent
          heading="Fire 🔥 and Safety Audit"
          backgroundImage={fireAndSafety}
          description="Safeguarding your premises and personnel is paramount. Our fire and safety audit rigorously evaluates your facility's compliance with fire codes and safety regulations. We conduct thorough inspections of fire protection systems, emergency exits, and safety procedures. Our findings and recommendations empower you to mitigate risks, prevent accidents, and maintain a secure environment for everyone within your building."
        />
        {/* <MainFeature1
          // subheading={<Subheading>About Us</Subheading>}
          heading="Fire and Safety Audit"
          description="Safeguarding your premises and personnel is paramount. Our fire and safety audit rigorously evaluates your facility's compliance with fire codes and safety regulations. We conduct thorough inspections of fire protection systems, emergency exits, and safety procedures. Our findings and recommendations empower you to mitigate risks, prevent accidents, and maintain a secure environment for everyone within your building."
          buttonRounded={false}
          imageSrc={fireAndSafety}
          textOnLeft={false}
        /> */}
      </Section>
      <Section id="esco">
        <MainFeature1
          // subheading={<Subheading>About Us</Subheading>}
          heading="ESCO"
          description="As an ESCO, we offer comprehensive energy solutions tailored to your needs. From energy audits and project implementation to financing and ongoing maintenance, we handle every aspect of your energy optimization journey. Our performance-based contracts ensure that our success is aligned with yours. By partnering with us, you can achieve significant energy savings and environmental benefits with minimal upfront investment."
          buttonRounded={false}
          imageSrc={esco}
          textOnLeft={false}
        />
      </Section>
      <Section id="energyManagement">
        <BackgroundAsImageWithCenteredContent
          heading="Energy Management System"
          backgroundImage={energyManagement}
          description="Implementing an EnMS is a strategic move towards sustained energy efficiency. Our EnMS solutions provide real-time monitoring, analysis, and control of your energy consumption. This data-driven approach enables proactive decision-making, empowering you to optimize energy use, track progress, and continuously improve efficiency. By integrating an EnMS, you demonstrate a commitment to sustainability while reaping the financial benefits of reduced energy costs."
        />
      </Section>
      <Section id="projects">
        <ProjectsTable
          title="Some Key Projects"
          description=""
          data={projects}
        />
      </Section>
      <Footer />
    </AnimationRevealPage>
  );
};
