import React from "react";
import tw from "twin.macro";
import HeaderBase, { NavLinks, NavLink } from "components/headers/light.js";
import HeroCarousel from "components/testimonials/HeroCarousel";
import { Container } from "components/misc/Layouts.js";
import { NavLinksComponent } from "helpers/NavLinks";
import { ROUTES } from "../../constants";

const Header = tw(HeaderBase)`bg-white fixed top-0 w-full z-50 shadow-md`;

export default () => {
  return (
    <>
      <Header links={
        <NavLinksComponent
          activeLink={ROUTES.HOME}
        />
      } />
      <Container>
        <HeroCarousel />
      </Container>
    </>
  );
};
