import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import tw from "twin.macro";
import { motion } from "framer-motion";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import { ReactComponent as ArrowLeftIcon } from "../../images/arrow-left-2-icon.svg";
import { ReactComponent as ArrowRightIcon } from "../../images/arrow-right-2-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-4.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-5.svg";

import agrovoltaics from "images/rudk/projects/agrovoltaics.jpg"
import biogas from "images/rudk/projects/biogas.jpg"
import commercialBuilding from "images/rudk/projects/commercial-buildings.jpg"
import cottonIndustry from "images/rudk/projects/cotton-industry.png"
import dairy from "images/rudk/projects/dairy.jpg"
import dairySubstainability from "images/rudk/projects/dairy-sustainability.jpg"
import highEnergy from "images/rudk/projects/high-energy.png"
import hotelIndustry from "images/rudk/projects/hotel-industry.jpg"
import industries from "images/rudk/projects/industries.jpg"
import manufacturing from "images/rudk/projects/manufacturing.png"
import microhydro from "images/rudk/projects/microhydro.png"
import petroleumDepot from "images/rudk/projects/petroleum-depot.jpg"
import solarDrinkingWater from "images/rudk/projects/solar-drinking-water.png"
import solarIrrigation from "images/rudk/projects/solar-irrigation.jpg"
import solarSolutions from "images/rudk/projects/solar-solutions.jpg"
import yakBrewing from "images/rudk/projects/yak-brewing.png"

import "slick-carousel/slick/slick.css";
import { Modal } from "components/misc/Modal.js";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto`;
const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const ProjectSliderContainer = tw.div``;
const ProjectSlider = styled(Slider)``;

const Project = tw(motion.a)`block sm:max-w-sm cursor-pointer mb-16 last:mb-0 sm:mb-0 odd:mx-4`;
const Image = styled(motion.div)(props => [
  `background-image: url("${props.$imageSrc}");`,
  tw`h-64 bg-cover bg-center rounded-2xl bg-no-repeat`
]);
const Title = tw.h5`mt-6 text-lg font-bold transition duration-300 group-hover:text-primary-500 mx-4`;
const Description = tw.p`mt-2 font-medium text-secondary-100 leading-loose text-sm`;

const ModalCard = tw.div`h-full flex! flex-col sm:border max-w-xl relative focus:outline-none`;
const ModalCardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-64 bg-cover bg-center rounded`
]);
const ModalTitle = tw.h3`text-2xl font-bold`;
const ModalDescription = tw.p`text-sm leading-loose mt-2`;

const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`absolute w-32 bottom-0 right-0 -z-10 text-primary-500 opacity-15 transform translate-x-2/3 translate-y-8`;

const projects = [
  {
    title: "Preparing Guideline and Ensuring Safety: Comprehensive Audit of Petroleum Depots in Nepal",
    imageSrc: petroleumDepot,
    details:
      `Rastriya Urja Dakshata Kendra Pvt. Ltd. completed the "Fire and Electrical Safety Audit in Petroleum Depots of Nepal and Preparation of Standard Procedure for Safety Audit" on December 31, 2023. This project focused on enhancing safety measures and ensuring the efficient operation of key petroleum depots through a series of meticulously planned tasks, including comprehensive safety audits at Non-Aviation Fuel Depot, Amlekhgunj, and Aviation Fuel Depot, Sinamangal, with infrastructure evaluation, hazard identification, and risk assessment. We formulated Electrical and Fire Safety Guidelines by comparing Nepalese and Indian standards, created emergency response plans for potential electrical and fire incidents, delivered extensive training to depot staff, and developed a comprehensive checklist for future audits.`,
    url: "/energy-efficiency#projects"
  },
  {
    title: "Establishing Energy Baselines: Comprehensive Energy Assessments for High-Energy Facilities",
    imageSrc: highEnergy,
    details:
      "Rastriya Urja Dakshata Kendra Pvt. Ltd. conducted energy assessments for high-energy consuming institutions, industries, commercial/public buildings, and private institutions. The primary objective was to analyze energy usage and recommend improvements for enhanced energy performance. We performed a detailed scope of work including walkthrough audits, data collection, and analysis. Key activities involved coordination with relevant officials, conducting walkthrough audits, and defining the scope of the energy audit. The assessment covered fuel type, power consumption, transformer ratings, and more. It identified energy-saving options and recommended measures for electrical and thermal improvements, including load management, power factor improvement, lighting systems, and waste heat recovery. A brief feasibility study for cogeneration and financial analysis of recommended energy efficiency interventions was also conducted. The findings culminated in a comprehensive report presented to AEPC and respective institutions, with a focus on continual improvement in line with ISO 50001:2011. Energy audits were conducted for United Wire and Cable Industries Pvt. Ltd., Gorkha Department Store, and Nepal Bank Limited. The assignment was performed under the call of Alternative Energy Promotion Center (AEPC).",
    url: "/energy-efficiency#projects"
  },
  {
    title: "Assuring Energy, Food and Water Security: Innovative Agrovoltaics in Madhesh Province",
    imageSrc: agrovoltaics,
    details:
      `Rastriya Urja Dakshata Kendra Pvt. Ltd. successfully completed the "Agrovoltaics Energy-efficient Combined Operative Farming with Automated Resourceful-Drip Module" project on June 21, 2023, in Tinghare, Sarlahi, supported by Renewable Energy for Rural Livelihood (RERL) under the Government of Nepal and UNDP. This innovative project integrated solar photovoltaic modules, drip irrigation, and fertigation techniques to optimize water and nutrient utilization for agricultural cultivation. Meticulous planning, from market analysis to community capacity building, ensured the project's success. This initiative sets a benchmark for sustainable agriculture, particularly in water-scarce regions, and we believe its success will inspire future endeavors in sustainable farming, impacting food and energy security policy in Nepal.`,
    url: "/research"
  },
  {
    title: "Enhancing Dairy Value Chains: Energy Efficiency Assessment in MSMEs",
    imageSrc: dairy,
    details:
      `Rastriya Urja Dakshata Kendra Pvt. Ltd. (RUDK) collaborated with Practical Action Nepal to conduct study for the "Energy Efficiency Assessment in MSMEs (Production, Collection, and Processing) on Dairy Value Chain" in Baglung and Makawanpur districts. The study aimed to achieve economic and environmental sustainability in the dairy value chain by conducting comprehensive energy efficiency audits. Specific objectives included analyzing existing energy use, identifying and recommending an appropriate energy mix, and suggesting technically feasible solutions to save fuel and reduce emissions. Highlighting the critical role of energy efficiency audits in the dairy value chain, the study identified various opportunities to save energy, reduce costs, and enhance modern energy technologies, ultimately contributing to reduced GHG emissions.`,
    url: "/research"
  },
  {
    title: "Brewing Sustainability: Energy and Resource Efficiency Audit at Yak Brewing Co.",
    imageSrc: yakBrewing,
    details:
      "Rastriya Urja Dakshata Kendra Pvt. Ltd. conducted a detailed energy and resource efficiency audit for Yak Brewing Co., a brewery committed to achieving carbon neutrality. As part of a comprehensive effort to transform the industry, our team was responsible for the in-depth audit and energy baseline preparation. This involved a meticulous analysis of the brewery's energy consumption, identifying potential areas for efficiency improvements and resource conservation. The audit encompassed all aspects of the brewing process and facility operations, providing actionable recommendations to enhance energy efficiency and reduce carbon emissions. Our work was instrumental in helping Yak Brewing Co. move towards its goal of becoming a carbon-neutral brewery, setting a standard for sustainability in the brewing industry.",
    url: "/energy-efficiency#projects"
  },
  {
    title: "Ensuring Sustainable Water Access: Performance Assessment of Rural Solar Drinking Water Pumping Projects in Karnali Province",
    imageSrc: solarDrinkingWater,
    details:
      "Rastriya Urja Dakshata Kendra Pvt. Ltd. conducted a comprehensive performance assessment of five solar pumping projects in Karnali Province, aimed at evaluating the current state of the solar plants, their operational efficiency, issues, community engagement, and the feasibility of rehabilitation if required. These solar pumping projects, crucial for apple farming, significantly uplift the living standards of the local community. The scope of work included a detailed desk study of existing reports, site visits to assess system performance, evaluation of efficiency, power quality, pumping performance, availability, and reliability, and identification of faults and operational challenges. The assessment also examined community involvement in project management and maintenance, incorporating feedback from local government and users on system quality and reliability. Recommendations were provided for project rehabilitation, including a detailed Bill of Quantities (BOQ), cost estimates, and rate analysis for the necessary rehabilitation and tendering process. The impact of this project is profound, ensuring sustainable water access, improving agricultural productivity, and enhancing the socio-economic conditions of the local community.",
    url: "/renewable-energy"
  },
  {
    title: "Harnessing Solar Power for Sustainable Cottage Industry in Rural Nepal",
    imageSrc: cottonIndustry,
    details:
      "Rastriya Urja Dakshata Kendra Pvt. Ltd. conducted a Detailed Feasibility Study (DFS) for the installation of a rooftop solar system at Paribartanshil Sana Kishan Krishi Sahakari Sanstha Limited, located in Tarapani-Soru RM-8, Mugu. This project, aimed at powering a cotton industry in a rural part of Nepal with no access to the national grid, addresses the critical need for sustainable and reliable energy sources in remote areas. The DFS included a comprehensive site analysis to assess the suitability of the rooftop for solar installation, evaluation of the solar irradiance, load analysis to determine energy requirements, and the design of an optimal solar PV system. Technical specifications for all components were detailed, ensuring the system's efficiency and reliability. Additionally, the feasibility study covered the economic analysis, including cost estimates, potential savings, and the return on investment, as well as an environmental impact assessment to ensure sustainability. The project provides reliable renewable energy for the cotton industry, reducing dependence on diesel generators and leading to significant cost savings. It improves operational efficiency, reduces greenhouse gas emissions, and contributes to climate change mitigation.",
    url: "/renewable-energy"
  },
  {
    title: "Evaluation of Determinants of Climate Resilient Solar Irrigation Farming in Nepal: A Pathway Towards 100% Renewable Energy",
    imageSrc: solarIrrigation,
    details:
      `Rastriya Urja Dakshata Kendra Pvt. Ltd. performed research funded by the World Wildlife Fund, Inc. under the “Multi Actor Partnerships (MAPs) for Implementing NDCs with 100% Renewable Energy (RE) for All in the Global South” project, supporting short-term research that promotes knowledge generation and policy advocacy to achieve 100% RE by 2050. This research investigates the sustainability factors influencing Solar Irrigation Pumps (SIPs) in Nepal, focusing on Sarlahi and Rautahat districts where SIP usage is substantial. Employing both online surveys via JotForm and physical surveys targeting farmers and local leaders, the study engages stakeholders with expertise in SIP technology. Using the Analytical Hierarchy Process (AHP) model, it prioritizes cost minimization and profit maximization, highlighting the critical role of environmental factors like carbon emissions. The study emphasizes the importance of aligning stakeholder perspectives to ensure cohesive understanding of SIP sustainability, advocating for green technologies, carbon credit exploration, water conservation practices, and robust waste management strategies. The research underscores the necessity for adaptable policies to enhance SIP sustainability in line with Nepal's commitment to 100% renewable energy, offering actionable recommendations for policymakers and practitioners to address environmental, social, and economic dimensions of solar irrigation farming.`,
    url: "/research"
  },
  {
    title: "Revitalizing Rural Energy: Rehabilitation Study for Micro Hydro in Sudurpashchim Province",
    imageSrc: microhydro,
    details:
      `Rastriya Urja Dakshata Kendra Pvt. Ltd. conducted a Detailed Feasibility Survey for The Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ) GmbH, REEEP to support the rehabilitation of Micro Hydro Projects (MHP) in Sudurpashchim Province, aiming to restore sustainable and reliable energy sources for rural communities. This assignment involves comprehensive Rehabilitation Studies and the preparation of Detailed Rehabilitation Reports. Objectives include collecting data from field observations, assessing damages in civil, mechanical, and electrical components, and evaluating the quality of project elements using a Detailed Assessment Form. The study also includes detailed design, estimating, and costing for damaged parts, along with engineering drawings of these components. By addressing these critical areas, the project seeks to enhance micro hydro systems, improve living standards, reduce reliance on non-renewable energy, and promote economic and environmental sustainability in the region.`,
    url: "/renewable-energy"
  },
  {
    title: "Transforming Dairy Sustainability: Enhancing Efficiency Energy Use and Reducing Emissions",
    imageSrc: dairySubstainability,
    details: <>
      <p>Rastriya Urja Dakshata Kendra Pvt. Ltd. conducted comprehensive energy audits at</p>
      <ul>
        <li>Martin Dairy in Kathmandu</li>
        <li>Bhawani Dairy in Chitwan</li>
        <li>SK Dairy in Nepalgunj</li>
        <li>Gayatri Dairy in Makwanpur</li>
        <li>Manakamana Dairy in Makwanpur</li>
        <li>Bagulng Dugdha Sahakari Sanstha Limited in Baglung</li>
      </ul>
      <p>These audits meticulously assessed the energy consumption across various processes in the dairy facilities, identifying opportunities for efficiency improvements. A significant outcome was achieved at SK Dairy, where the audit led to a 31% decrease in electrical energy consumption, a 24% reduction in the monthly electricity bill from NEA, and a remarkable 37% decrease in GHG emissions. These improvements have significantly enhanced the sustainability and environmental friendliness of the dairy operations, setting a benchmark for energy efficiency in the industry.</p>
    </>,
    url: "/#"
  },
  {
    title: "Empowering Technicians: Comprehensive Energy Efficiency Training for Manufacturing Industries",
    imageSrc: manufacturing,
    details:
      `Rastriya Urja Dakshata Kendra Pvt. Ltd. developed a comprehensive 96-hour course on Energy Efficiency Training for technicians in manufacturing industries across Bagmati Province and throughout Nepal under the ENSSURE/HELVETAS Nepal project. This initiative aimed to enhance the skills and knowledge of industry technicians, promoting energy efficiency practices in their respective fields. Following the course development, we conducted Energy Efficiency Training for Manufacturing Industry Operators and Maintenance Staff (EEMIO) with a focus on electrical aspects. Organized by the Confederation of Bagmati Province Industries (CBPI) and supported by the ENSSURE project, the training provided in-depth, hands-on experience and theoretical knowledge. The program covered various aspects of energy efficiency, including system optimization, energy-saving techniques, and the implementation of best practices. This training initiative significantly contributed to building a skilled workforce capable of improving energy performance and sustainability in manufacturing industries.`,
    url: "/research"
  },
  {
    title: "Powering Rural Nepal: Innovative Solar Solutions and Design Consulting",
    imageSrc: solarSolutions,
    details:
      `Rastriya Urja Dakshata Kendra Pvt. Ltd., in collaboration with the Alternative Energy Promotion Center (AEPC), undertook a comprehensive feasibility study for Solar Mini Grids across rural municipalities in Nepal. This project involved evaluating potential sites, assessing geographical and infrastructural factors, and preparing detailed implementation reports to enhance renewable energy access in remote areas.`,
    details2:
      `In addition, RUDK provided specialized design consulting for a 100 kWp Solar Mini Grid at the Makari Gad Hydro project in Darchula. This included creating detailed designs, advising on system integration, and providing cost estimates and engineering drawings to boost the energy capacity and reliability of the existing hydro infrastructure with solar technology.`,
    url: "/#"
  },
  {
    title: "Optimizing Efficiency: Comprehensive Energy Studies in Commercial Buildings in Kathmandu",
    imageSrc: commercialBuilding,
    details:
      `Rastriya Urja Dakshata Kendra Pvt. Ltd. conducted several crucial projects aimed at enhancing energy performance and resource efficiency across various prestigious institutions. At the UN Building, an Electrical Load Analysis was performed to optimize energy distribution and usage. Similarly, a comprehensive energy and resource efficiency audit was carried out at the British Embassy in Kathmandu, focusing on reducing energy consumption and enhancing sustainability practices. Additionally, a Building and Resource Efficiency Energy Audit was executed at the World Bank Group Office in Kathmandu, ensuring the highest standards of energy efficiency were met.`,
    details2:
      `These audits identified critical areas for energy and resource efficiency improvements, leading to significant reductions in energy consumption, operational costs, and carbon emissions. By implementing recommended measures, these institutions enhanced their energy performance, contributing to a more sustainable and environmentally responsible operation.`,
    url: "/#"
  },
  {
    title: "Optimizing Industrial Efficiency and Safety: Comprehensive Energy Studies in Nepalese Industries",
    imageSrc: industries,
    details:
      `Rastriya Urja Dakshata Kendra Pvt. Ltd. conducted a Detailed Energy Audit at National Health Care Pvt. Ltd., focusing on optimizing energy use in healthcare facilities where reliability and efficiency are critical. Additionally, we developed and designed an Electrical Safety System tailored to enhance safety standards within the existing factory setup, ensuring a safer and more efficient working environment.`,
    details2:
      `Furthermore, a Critical Appliances Energy Audit was performed at Bottlers Nepal Terai, aimed at identifying energy-saving opportunities in key equipment within the beverage industry. These audits not only highlighted significant areas for energy efficiency improvements but also contributed to reducing operational costs and minimizing the environmental footprint of these institutions. Rastriya Urja Dakshata Kendra Pvt. Ltd also conducted Electrical and Fire Safety audit at Jayshree Polymers Pvt. Ltd, focusing on the fire and safety standards within the factory and prepare a certification for the industry.`,
    url: "/#"
  },
  {
    title: "Enhancing Green Hospitality: Energy Efficiency and Carbon Footprint Reduction in Hotel Industry",
    imageSrc: hotelIndustry,
    details:
      `Rastriya Urja Dakshata Kendra Pvt. Ltd. conducted an in-depth Energy Performance Analysis of Mandakini Hotel Pvt. Ltd. and a comprehensive Baseline Preparation and Detailed Energy Audit at Vivanta Kathmandu. These studies were pivotal in advancing green hospitality practices, focusing on reducing the carbon footprint within the hotel industry. At Vivanta Kathmandu, our detailed analysis and recommendations are set to play a crucial role in the hotel achieving certification for sustainable practices. The outcomes of these projects underscore the importance of energy efficiency in fostering environmentally responsible and cost-effective hotel operations.`,
    url: "/#"
  },
  {
    title: "Empowering Sustainable Communities: User Survey for Biogas Program of Activity (PoA) 2023/24",
    imageSrc: biogas,
    details:
      `Rastriya Urja Dakshata Kendra Pvt. Ltd. conducted a comprehensive User Survey for the Biogas Program of Activity (PoA) 2023/24, commissioned by the Alternative Energy Promotion Center (AEPC). The primary objective of this assignment was to assess the monitoring parameters outlined in the Program of Activity Design Document (PoA DD) and the PoA DD for Gold Standard for the Global Goals (GS4GG). The study focused on evaluating the operational status of digesters across various ecological zones, the impact on non-renewable biomass usage, and trends related to fuel wood gathering, prices, and types. Additionally, the survey assessed the reduction in indoor smoke and related health benefits, time savings, access to toilets, and the use of bio-slurry as a replacement for chemical fertilizers. By addressing these factors, the study provided crucial insights into the community benefits and overall satisfaction of beneficiaries, reinforcing the effectiveness and sustainability of the biogas program in promoting clean energy and improving livelihoods in Nepal.`,
    url: "/#"
  },

];

export default () => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [selectedProject, setSelectedProject] = React.useState(null);
  let sliderRef = React.useRef(null);

  const projectBackgroundSizeAnimation = {
    rest: {
      backgroundSize: "100%"
    },
    hover: {
      backgroundSize: "110%"
    }
  };

  const onProjectClick = (project) => {
    sliderRef.slickPause();
    setSelectedProject(project);
    setModalOpen(true);
  }

  const onModalClose = () => {
    setModalOpen(false);
    sliderRef.slickPlay();
  }

  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>Our Featured Projects</HeadingTitle>
        </HeadingInfoContainer>
        <ProjectSliderContainer>
          <ProjectSlider
            ref={slider => {
              sliderRef = slider;
            }}
            arrows={false}
            // dots={true}
            infinite={true}
            pauseOnHover={false}
            slidesToShow={3}
            slidesToScroll={1}
            autoplay={true}
            speed={2000}
            autoplaySpeed={3000}
            cssEase="ease"
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  initialSlide: 1
                }
              },
              {
                breakpoint: 640,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]}
          >
            {projects.map((project, index) => (
              <Project key={index} onClick={() => onProjectClick(project)} className="group" initial="rest" whileHover="hover" animate="rest">
                <Image
                  transition={{ duration: 0.3 }}
                  variants={projectBackgroundSizeAnimation}
                  $imageSrc={project.imageSrc}
                />
                <Title>{project.title}</Title>
                <Description>{project.description}</Description>
              </Project>
            ))}
          </ProjectSlider>
        </ProjectSliderContainer>
      </Content>
      {selectedProject &&
        <Modal isOpen={isModalOpen} onClose={onModalClose}>
          <ModalCard>
            <ModalCardImage imageSrc={selectedProject.imageSrc} />
            <ModalTitle>{selectedProject.title}</ModalTitle>
            <ModalDescription>{selectedProject.details}</ModalDescription>
            {selectedProject.details2 && <ModalDescription>{selectedProject.details2}</ModalDescription>}
          </ModalCard>
        </Modal>
      }
      <DecoratorBlob2 />
    </Container>
  );
};
