import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "../misc/Headings.js";

const Container = tw.div`relative bg-gradient-to-b from-10% to-60% from-indigo-900 to-blue-300`;
const Content = tw.div`max-w-screen-xl mx-auto py-5 md:py-8`;
const Table = tw.table`m-auto p-4 w-11/12 border-collapse shadow-2xl font-semibold`;
const THead = styled.thead`
  ${tw`text-gray-100 uppercase`}
  tr {
    ${tw`bg-teal-700`}
  }
`
const TRow = tw.tr``;
const TH = tw.th`text-left p-4`;
const TD = tw.td`p-4`;
const TBody = styled.tbody`
  tr {
    ${tw``}
  }
`

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight text-gray-100`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

export default ({
  title = 'Title',
  description = 'Description',
  data = [],
}) => {
  const columns = [
    {
      header: "Assignment",
      id: "assignment"
    },
    {
      header: "Location",
      id: "location"
    },
    {
      header: "Client",
      id: "client"
    }
  ]

  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <Heading>{title}</Heading>
          <Description>{description}</Description>
        </HeadingInfoContainer>
        <Table>
          <THead>
            <TRow>
              {columns.map(column => (
                <TH key={column.id}>{column.header}</TH>
              ))}
            </TRow>
          </THead>
          <TBody>
            {data.map(row => (
              <TRow key={row.id}>
                <TD key={'assignment'}>
                  {row['assignment']}
                </TD>
                <TD key={'location'}>
                  {row['location']}
                </TD>
                <TD key={'client'}>
                  {row['client']}
                </TD>
              </TRow>
            ))}
          </TBody>
        </Table>
      </Content>
    </Container>
  );
};
