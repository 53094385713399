import React from "react";
import tw from "twin.macro";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

/* font-size: 1em;
    position: absolute;
    top: 0.5em;
    right: 0.5em;
 */
export const CloseButton = tw.button`
  absolute top-2 right-2 z-20 focus:outline-none hocus:text-red-500 transition duration-300 rounded-2xl cursor-pointer
`;

export const Modal = ({ isOpen, hasCloseBtn = true, onClose, children }) => {
  const [isModalOpen, setModalOpen] = React.useState(isOpen);
  const modalRef = React.useRef(null);
  const modalContentRef = React.useRef(null);

  const handleCloseModal = () => {
    onClose && onClose();
    setModalOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      handleCloseModal();
    }
  };

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (isModalOpen && modalRef.current && !modalContentRef.current.contains(event.target)) {
        handleCloseModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);

  }, [isModalOpen]);

  React.useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  React.useEffect(() => {
    const modalElement = modalRef.current;
    if (modalElement) {
      isModalOpen ? modalElement.showModal() : modalElement.close();
    }
  }, [isModalOpen]);

  return (
    <dialog ref={modalRef} onKeyDown={handleKeyDown} className="modal">
      <div ref={modalContentRef}>
        {hasCloseBtn && (
          <CloseButton onClick={handleCloseModal}>
            <CloseIcon tw="w-6 h-6"/>
          </CloseButton>
          
        )}
        {children}
      </div>
    </dialog>
  );
}
