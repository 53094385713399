import React, { useState } from "react";
import tw from "twin.macro";
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { FaAngleDown } from "react-icons/fa";

import { NavLinks, NavLink } from "components/headers/light.js"
import { MENU_ROUTES } from "../constants";

const NavItem = styled.div``;

const SubRoutes = tw.div`
  absolute z-50 flex flex-col bg-gray-100 py-1 px-4 text-gray-800 shadow-xl
  border-2 border-solid border-gray-100
`;

const SubRoute = tw.div`
  px-2 py-2
  my-2 lg:mx-4
  font-semibold tracking-wide transition-all duration-300
  hocus:text-primary-500
`;

const ArrowDown = styled(FaAngleDown)`
  ${tw``};
`;

const SubMenu = ({ subRoutes, routePath }) => (
  <SubRoutes>
    {subRoutes.map((subRoute, index) => (
      <SubRoute key={index} >
        <Link className="animated" to={`${routePath}#${subRoute.sectionId}`}>
          {subRoute.name}
        </Link>
      </SubRoute>
    ))}
  </SubRoutes>
);

const NavItemComponent = ({ route, activeLink }) => {
  const [showSubMenu, setShowSubMenu] = useState(false);

  const handleMouseEnter = () => setShowSubMenu(true);
  const handleMouseLeave = () => setShowSubMenu(false);

  return (
    <NavItem
      onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    >
      <NavLink
        className="animated"
        href={route.route}
        active={route.route === activeLink}
      >
        {route.title}
        {route.subRoutes && <ArrowDown />}
      </NavLink>

      {showSubMenu && route.subRoutes && (
        <SubMenu subRoutes={route.subRoutes} routePath={route.route} />
      )}
    </NavItem>
  );
};

const NavLinksComponent = ({ activeLink }) => (
  <NavLinks>
    {MENU_ROUTES.map((route, index) => (
      <NavItemComponent key={index} route={route} activeLink={activeLink} />
    ))}
  </NavLinks>
);

export { NavLinksComponent }
