import React from "react";
import tw from "twin.macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container as ContainerBase, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionDescription } from "components/misc/Typography";

const Container = tw(ContainerBase)`xl:-my-20 -mx-8 px-8`;
/* const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl`;
const Subheading = tw(SubheadingBase)`text-gray-100 text-center`;
const Description = tw(SectionDescription)`text-gray-400 text-center mx-auto max-w-screen-md`; */

const StatsContainer = tw.div`bg-gray-800 text-white flex flex-wrap lg:flex-nowrap max-w-screen-xl ml-auto mr-0 xl:rounded-bl-xl`;
const Stat = tw.div`flex flex-col text-center py-6 tracking-wide w-full md:w-1/2 lg:w-1/5`;
const StatsHeader = tw.div`bg-gray-800 text-white py-6 max-w-screen-xl ml-auto text-3xl content-center text-center font-bold xl:rounded-tl-xl`;
const StatKey = tw.div`text-lg font-medium`;
const StatValue = tw.div`text-4xl md:text-5xl font-black text-primary-500`;
const StatUnit = tw.span`text-3xl md:text-4xl ml-1`;

export default ({
  subheading = "",
  heading = "",
  description = "Our contribution for preserving environment and combating climate change",
  stats = [
    {
      key: "Industry Reach",
      value: "200",
      unit: "+"
    },
    {
      key: "Happy clients",
      value: "50",
      unit: "+"
    },
    {
      key: "Energy Audits",
      value: "30",
      unit: "+"
    },
    {
      key: "Potential Energy Saving",
      value: "1.5",
      unit: "GWh"
    },
    {
      key: "Potential Emission Saved",
      value: "1500",
      unit: "tCO2"
    },
  ]
}) => {
  return (
    <Container>
{/*         <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeadingContainer> */}
        <StatsHeader>Our contribution in Energy Efficiency and Sustainability</StatsHeader>
        <StatsContainer>
          {stats.map((stat, index) => (
            <Stat key={index}>
              <StatValue>{stat.value}<StatUnit>{stat.unit}</StatUnit></StatValue>
              <StatKey>{stat.key}</StatKey>
            </Stat>
          ))}
        </StatsContainer>
    </Container>
  );
};
