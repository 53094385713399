import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import HeaderBase from "components/headers/light.js"
import Footer from "components/footers/Footer.js";
import MainFeature1 from "components/features/TwoColWithButton.js";

import { NavLinksComponent } from "helpers/NavLinks";
import { ROUTES } from "../constants";

import backgroundImage from "images/rudk/renewable-energy/hero-background.jpg";
import solar from "images/rudk/renewable-energy/solar.jpg";
import microHydro from "images/rudk/renewable-energy/micro-hydro.jpeg";
import BackgroundAsImageWithCenteredContent from "components/hero/BackgroundAsImageWithCenteredContent";
import ProjectsTable from "components/tables/ProjectsTable";

const Header = tw(HeaderBase)`max-w-none`;
const Section = tw.section``;

const projects = [
  {
    "id": "1",
    "assignment": "Performance Assessment of Rural Solar Drinking Water Pumping Projects in Jumla District",
    "location": "Jumla",
    "client": "Renewable Energy for Rural Livelihood (RERL)"
  },
  {
    "id": "2",
    "assignment": "Detail Site Survey for Implementation of Solar Mini Grid on Rural Municipalities of  Nepal",
    "location": "Karnali Province",
    "client": "Alternative Energy Promotion Center (AEPC)"
  },
  {
    "id": "3",
    "assignment": "Study, design, and implementation of Agrovoltaics ECOFARM",
    "location": "Lalbandi, Sarlahi",
    "client": "Tinghare Akikrit Gramin Bikash Khanepani Pariyojana"
  },
  {
    "id": "4",
    "assignment": "Solar Mini grid Design consulting, 100 kWp",
    "location": "Darchula",
    "client": "Makari Gad Hydro"
  },
  {
    "id": "5",
    "assignment": "Preparation of Reports for rehabilitation of 7 micro hydro projects in Sudur Paschim Province",
    "location": "Sudurpaschim Province",
    "client": "GIZ Nepal"
  },
  {
    "id": "6",
    "assignment": "Rehabilitation report preparation of five different MHP’s in five different districts",
    "location": "Dolpa, Humla, Darchula, Doti",
    "client": "Alternative Energy Promotion Center (AEPC)"
  },
  {
    "id": "7",
    "assignment": "Detail Feasibility Study of Patkulauna Badh MHP, Jumla",
    "location": "Jumla",
    "client": "Patkulauna Badh Hydro User committee"
  },
];

export default () => {
  return (
    <AnimationRevealPage disabled>
      <Header links={
        <NavLinksComponent
          activeLink={ROUTES.RENEWABLE_ENERGY}
        />
      } />
      <Section id="overview">
        <BackgroundAsImageWithCenteredContent
          heading="Renewable Energy"
          backgroundImage={backgroundImage}
          description="Nepal is committed to increasing its share of renewable energy by 2030, aligning with SDG 7.2 to enhance global renewable energy capacity. This goal supports Nepal's ambition to achieve net-zero emissions by 2045. This effort is crucial in the global mission as per Paris Agreement to limit warming to 1.5°C, with clean energy integration being a pivotal strategy. Nepal's commitment underscores its focus on augmenting renewable energy and reducing emissions, aligning with international climate goals and promoting inclusive and sustainable development."
          description2="Rastriya Urja Dakshata Kendra Pvt. Ltd. endorsed the integration of renewable energy sources to reduce carbon footprints and reduce the dependence on fossil fuels. Rastriya Urja Dakshata Kendra Pvt. Ltd. also strives to impart practical knowledge about renewable energy to locals, enabling them to directly apply these insights to their community-level."
          hero={true}
        />
      </Section>
      <Section id="solarEnergy">
        <MainFeature1
          heading="Solar Energy"
          description="Rastriya Urja Dakshata Kendra Pvt. Ltd. has been actively promoting solar energy initiatives to enhance rural and urban livelihoods. Our programs focus on harnessing renewable energy to improve energy access, address water supply challenges for drinking water, and improve irrigation. Rastriya Urja Dakshata Kendra Pvt. Ltd. has been working in collaboration with various provincial, national, and international stakeholders in the integration of solar grid and solar pumping. Through training and practical exercises, we ensure participants are well-equipped to maintain and operate solar pumping systems, as well as provide consulting on solar mini-grid design and performance assessment, thereby fostering long-term sustainability."
          buttonRounded={false}
          imageSrc={solar}
        />
      </Section>
      <Section id="microHydro">
        <MainFeature1
          heading="Micro Hydro Energy"
          description="Rastriya Urja Dakshata Kendra Pvt. Ltd. endeavors in the growth of hydro energy projects—those that aim to cause sustainable development and enhance energy security in rural areas. Our efforts involve thorough assessments, design, and implementation to ensure the reliability and resilience of micro hydro projects. Our detailed feasibility studies and careful design of transmission and distribution systems ensure effective and efficient delivery of hydroelectric power."
          buttonRounded={false}
          imageSrc={microHydro}
          textOnLeft={false}
        />
      </Section>
      {/* <Section id="microHydro">
        <BackgroundAsImageWithCenteredContent
          heading="Micro Hydro Energy"
          backgroundImage={microHydro}
          description="Rastriya Urja Dakshata Kendra Pvt. Ltd. endeavors in the growth of hydro energy projects—those that aim to cause sustainable development and enhance energy security in rural areas. Our efforts involve thorough assessments, design, and implementation to ensure the reliability and resilience of micro hydro projects. Our detailed feasibility studies and careful design of transmission and distribution systems ensure effective and efficient delivery of hydroelectric power."
        />
      </Section> */}
      <Section id="projects">
        <ProjectsTable
          title="Projects"
          description=""
          data={projects}
        />
      </Section>
      <Footer />
    </AnimationRevealPage>
  );
};
