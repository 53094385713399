import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import background from "images/rudk/energy-efficiency/hero-background.jpg";

const Container = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`relative bg-center bg-cover h-[50vh] min-h-112`
]);

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;
const HeadingH2 = styled.h2`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-100`;

export default ({
  actionTitle = "More details below",
  backgroundImage = background,
  description = "",
  description2 = "",
  heading = "",
  hero = false,
}) => {
  return (
    <Container imageSrc={backgroundImage}>
      <OpacityOverlay />
      <HeroContainer>
        <Content>
          {hero ? <Heading>{heading}</Heading> : <HeadingH2>{heading}</HeadingH2>}
          <Description>{description}</Description>
          {description2 && <Description>{description2}</Description>}
          {/* <PrimaryAction>{actionTitle}</PrimaryAction> */}
        </Content>
      </HeroContainer>
    </Container>
  );
};
