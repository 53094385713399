import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import LogoImage from "images/rudk/logo-new.png";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as LinkedInIcon } from "images/linkedin-icon.svg";
import { ROUTES } from "constants";

const Container = tw.div`relative text-white px-8 py-8 bg-gradient-to-b from-gray-900 from-0% to-green-900 to-100%`
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const TwoColumns = tw.div`flex flex-wrap text-center md:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-4 sm:px-0 sm:w-1/2 md:w-auto mt-12`;
const Heading = tw.h2`text-2xl font-bold`;
const Description = tw.p`text-lg text-white`;
const ColumnHeading = tw.h5`text-lg uppercase font-bold`;

const LinkList = tw.ul`mt-6 text-sm font-medium list-none pl-0`;
const LinkListItem = tw.li`mt-3`;

const Link = tw.a`
  text-lg my-2 lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent text-white
`;

const SubscribeNewsletterColumn = tw(Column)`text-center lg:text-left w-full! lg:w-auto! mt-12`;
const SubscribeNewsletterContainer = tw.div`max-w-sm mx-auto lg:mx-0 `;
const SubscribeText = styled.p`
  ${tw`text-lg mt-2 lg:mt-6 font-medium text-white`}
  a {
    ${tw`text-white`}
  }
`;

const Divider = tw.div`my-16 border-b-2 border-gray-300 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between bg-white rounded-2xl p-4`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-48`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-white`;

const CopywrightNotice = tw.p`text-lg text-center font-medium text-gray-700`;

const SocialLinksContainer = tw.div`flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-900 text-white hover:bg-gray-700 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default () => {
  return (
    <Container>
      <Content>
        <TwoColumns>
          <Column>
            <Heading>Rastriya Urja Dakshata Kendra Pvt. Ltd.</Heading>
            <Description>Empowering communities through sustainable energy solutions.</Description>
            <SubscribeNewsletterColumn>
              <SubscribeNewsletterContainer>
                <address>
                  <SubscribeText>
                    <a href="mailto:info@nationaleecenter.com">Email: info@nationaleecenter.com</a>
                  </SubscribeText>
                  <SubscribeText>
                    <a href="tel:+977 984-3305814">+977 984-3305814</a>
                  </SubscribeText>
                  <SubscribeText>
                    <a href="tel:+977 985-1135107">+977 985-1135107</a>
                  </SubscribeText>
                </address>
              </SubscribeNewsletterContainer>
            </SubscribeNewsletterColumn>
          </Column>
          <Column>
            <LinkList>
              <LinkListItem>
                <Link href={ROUTES.ABOUT}>About Us</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href={ROUTES.ENERGY_EFFICIENCY}>Energy Efficiency</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href={ROUTES.RENEWABLE_ENERGY}>Renewable Energy</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href={ROUTES.CLIMATE}>Climate</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href={ROUTES.RESEARCH}>Research</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href={ROUTES.RESOURCES}>Resources</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href={ROUTES.CONTACT_US}>Contact Us</Link>
              </LinkListItem>
            </LinkList>
          </Column>
        </TwoColumns>
        <ThreeColRow>
          <LogoContainer>
            <LogoImg src={LogoImage} />
            <LogoText></LogoText>
          </LogoContainer>
          <CopywrightNotice>&copy; {(new Date()).getFullYear()} Rastriya Urja Dakshata Kendra Pvt. Ltd. All Rights Reserved.</CopywrightNotice>
          <SocialLinksContainer>
            <SocialLink target="_blank" href="https://www.facebook.com/NationalEECenter/">
              <FacebookIcon />
            </SocialLink>
            <SocialLink target="_blank" href="https://www.linkedin.com/company/rastriya-urja-dakshata-kendra-pvt-ltd/">
              <LinkedInIcon />
            </SocialLink>
          </SocialLinksContainer>
        </ThreeColRow>
      </Content>
    </Container>
  );
};
