import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import HeaderBase from "components/headers/light.js"
import Footer from "components/footers/Footer.js";
import { PrimaryText, PrimaryTextBold, PrimaryTextUnderline, SectionDescription } from "components/misc/Typography.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { NavLinksComponent } from "helpers/NavLinks";
import { ROUTES } from "../constants";
import BackgroundAsImageWithCenteredContent from "components/hero/BackgroundAsImageWithCenteredContent";

import backgroundImage from "images/rudk/climate/climate.jpg";
import climate1 from "images/rudk/climate/climate1.jpg";
import climate2 from "images/rudk/climate/climate2.jpg";
import BgHeroSmall from "components/hero/BgHeroSmall";
import useScrollToHash from "helpers/useScrollToHash";

const Header = tw(HeaderBase)`max-w-none`;
const Section = tw.section``;
const Container = tw.div`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-evenly max-w-screen-2xl mx-auto py-5 md:py-6`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`text-lg text-black text-justify max-w-6xl px-2`;
const Column = styled.div`
  ${tw`w-full md:w-1/2 lg:w-1/2 max-w-lg`}
`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-96 bg-cover bg-center rounded-2xl`
]);
const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full px-2 py-4`}
  .textContainer {
    ${tw`mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

export default () => {
  useScrollToHash();

  const heading = '';
  const subheading = '';
  const description1 = <>
    <PrimaryTextUnderline>Rastriya Urja Dakshata Kendra Pvt. Ltd.</PrimaryTextUnderline> is committed to fostering practices that ensure a harmonious coexistence between our energy needs and the well-being of our planet. It plays a crucial role in combating climate change. By using energy more efficiently and reducing overall consumption, the demand for fossil fuels decreases, leading to fewer carbon emissions.
  </>;
  const description2 = 'Not only does it help industries reduce their greenhouse gas emissions by increasing energy efficiency, but it also conducts energy audits to assess energy consumption, emissions, and practices in industries. By identifying losses and conservation opportunities, we provide recommendations through an energy management action plan to increase energy efficiency and reduce GHG emissions, which is one of the major steps in combating climate change.';
  const description3 = 'We intend to help businesses and individuals understand the importance of energy efficiency and it’s contribution to climate change mitigation and adaptation.';
  const description4 = <>
    <PrimaryTextUnderline>Rastriya Urja Dakshata Kendra Pvt. Ltd.</PrimaryTextUnderline> blazed a trail with its groundbreaking <PrimaryTextBold>"INSTALLATION REPORT FOR STUDY, DESIGN AND IMPLEMENTATION OF AGROVOLTAICS ECOFARM PROJECT"</PrimaryTextBold> in Tinghare Sarlahi, marking a first for Nepal. Here, Rastriya Urja Dakshata Kendra Pvt. Ltd. introduced a cutting-edge, energy-efficient semi-automated drip irrigation and fertigation system. What is the project's core idea? Harnessing solar power to pump water more sustainably while ingeniously utilizing the space beneath solar panels for agrovoltaic farming. This innovative approach not only conserves energy but also champions climate change resilience through smart, eco-friendly practices.
  </>

  const greenFinance = {
    heading: 'Green Finance',
    description1: 'Green Finance is a new terminology in context of Nepal. We have been involved in renewable energy and energy efficiency projects, through which we have identified the higher importance of good governing financing mechanism to fulfill the global sustainable energy target. Currently, Nepal has minimal projects relating to green financing. Major banks across Nepal are implementing ecological mechanism such as but not limited to, paperless banking and e banking to reduce waste and unnecessary carbon emissions. In the future, we hope that with our expertise; we can advise on national and international level projects funded by the bank in relation to green financing, such as energy efficiency audits, decarbonization, net zero emission projects, and fossil fuel reduction projects (green economy).',
    description2: 'Although we have been through numbers of projects that can be closely termed as green investment project, but there is no exact definition for the green investment/loan/projects/data in Nepal. This hinders regulators, implementing agencies, and other stakeholders to effectively and efficiently mobilize resource, including private sector investments, towards Nepal’s sustainable development agenda.',
    description3: 'There is a sense of hesitation for the initial investment in creating green projects or retrofitting the conventional ones into green ones. The client is unsecure about the investment as it goes to the higher side.',
    description4: 'Being in line with our company goals, we seek to develop financial tools such as green taxonomy, which can be crucial for financial institutions to expand the countries green credit portfolios and guide green finance with efficiency and integrity.',
    description5: <>
      Ratriya Urja Dakshata Kendra has been researching this aspect of sustainable financing in Nepal, starting with support to <PrimaryTextBold>GCPF partner financial institutions</PrimaryTextBold> in Nepal in green lending capacity building and implementation. Rastriya Urja Dakshata Kendra Pvt. Ltd. offers to foster a financial environment where investments contribute to achieving energy security, reducing carbon emissions, and advancing sustainable development goals.
    </>
  }
  const cards = [
    { // 1
      imageSrc: climate1,
      title: "",
    },
    { // 2
      imageSrc: climate2,
      title: "",
    },
  ];

  return (
    <AnimationRevealPage disabled>
      <Header links={
        <NavLinksComponent
          activeLink={ROUTES.CLIMATE}
        />
      } />
      <Section id="overview">
        <BackgroundAsImageWithCenteredContent
          heading="Climate"
          backgroundImage={backgroundImage}
          description={"The world is facing unprecedented climate challenges, from extreme weather events to rising global temperatures. At Rastriya Urja Dakshata Kendra Pvt. Ltd., we recognize the urgency of addressing these issues. As pioneers in energy efficiency, we offer innovative solutions to reduce energy consumption and carbon emissions. Our vision is to build a sustainable future where responsible energy practices lead to a healthier planet. By choosing us, you become part of the solution, actively contributing to mitigating climate change and ensuring a brighter future for generations to come."}
          //old description={"At Rastriya Urja Dakshata Kendra Pvt. Ltd., we recognize that the world is grappling with severe climate challenges that demand immediate and concerted action. The escalating frequency of extreme weather events, including unprecedented heatwaves, intense storms, and severe droughts, along with rising global temperatures and the alarming rate of polar ice melt, underscores the urgency of addressing climate change. These disruptions threaten ecosystems, endanger biodiversity, and put immense pressure on communities and economies worldwide."}
          //old description2={"As a pioneer in energy efficiency, we are committed to tackling these global issues head-on by providing cutting-edge solutions that reduce energy consumption and lower carbon emissions. Our vision is to foster a sustainable future where innovative technologies and responsible practices drive significant environmental benefits. By choosing Rastriya Urja Dakshata Kendra Pvt. Ltd. , you join us in our mission to mitigate the impacts of climate change, enhance energy resilience, and contribute to a healthier, more sustainable planet for current and future generations."}
          hero={true}
        />
      </Section>
      <Section  id="climate">
        <Container>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading>}
          {description1 && <Description>{description1}</Description>}
          {description2 && <Description>{description2}</Description>}
          {description3 && <Description>{description3}</Description>}
          {description4 && <Description>{description4}</Description>}
          {cards.map((card, i) => (
            <Column key={i}>
              <Card>
                <Image imageSrc={card.imageSrc} />
                <span className="textContainer">
                  <span className="title">{card.title}</span>
                </span>
              </Card>
            </Column>
          ))}
        </Container>
      </Section>
      <Section id="greenFinance">
        <Container >
          <Heading>{greenFinance.heading}</Heading>
          <Description>{greenFinance.description1}</Description>
          <Description>{greenFinance.description2}</Description>
          <Description>{greenFinance.description3}</Description>
          <Description>{greenFinance.description4}</Description>
          <Description>{greenFinance.description5}</Description>
        </Container>
      </Section>
      <Footer />
    </AnimationRevealPage>
  );
};
