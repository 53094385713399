import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import tw from "twin.macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import imagePlaceholder from "images/rudk/user-placeholder.png";

import cni from "images/rudk/customers/partners/cni.jpg";
import koelFresh from "images/rudk/customers/partners/koel-fresh.webp";
import kathmanduUniversity from "images/rudk/customers/partners/kathmandu-university.png"
import morangChamber from "images/rudk/customers/partners/mornag-chamber.png";

import aasman from "images/rudk/customers/clients/aasaman-nepal.jpg";
import aepc from "images/rudk/customers/clients/aepc.png";
import bhawani from "images/rudk/customers/clients/bhawani.png";
import flensburg from "images/rudk/customers/clients/flensburg.svg";
import giz from "images/rudk/customers/clients/giz.png";
import gorakha from "images/rudk/customers/clients/gds.jpg";
import helvetas from "images/rudk/customers/clients/helvetas.png";
import madenepal from "images/rudk/customers/clients/made-nepal.png";
import martins from "images/rudk/customers/clients/martins.jpg";
import nepalbank from "images/rudk/customers/clients/nepal-bank.png";
import noc from "images/rudk/customers/clients/nepaloilcorp.png";
import swisscontact from "images/rudk/customers/clients/logo-swisscontact.svg";
import undp from "images/rudk/customers/clients/undp-logo-blue.svg";
import unitedwire from "images/rudk/customers/clients/united-wire.png";
import urban from "images/rudk/customers/clients/urban-logo.jpg";
import worldbank from "images/rudk/customers/clients/worldbank.svg";
import practicalAction from "images/rudk/customers/clients/practical-action.svg";

import d2o from "images/rudk/customers/advisors/d2o.jpg"
import idam from "images/rudk/customers/advisors/idam.png"

import "slick-carousel/slick/slick.css";

const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8 bg-gray-100 py-8`;
const Content = tw.div`max-w-screen-xl mx-auto`;

const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto text-center text-gray-300`;

const TestimonialContainer = tw.div`mb-12`;
const TestimonialHeader = tw(SubheadingBase)`text-center text-2xl`;

const ClientsSlider = styled(Slider)``;
// const ClientsSlider = tw.ul`flex flex-wrap gap-12 justify-center list-none mb-24`;

const Client = tw.li`h-16 w-32 mx-4`;
const ClientLogo = tw.img`h-16`;

export default ({
  subheading = "",
  heading = "Trusted and loved by",
  description = "",
  partnersHeading = "Partnership with",
  partners = [
    {
      customerName: "Kathmandu University",
      customerProfile: "",
      logoSrc: kathmanduUniversity,
      imageSrc: imagePlaceholder,
      quote: ""
    },
    {
      customerName: "CNI",
      customerProfile: "",
      logoSrc: cni,
      imageSrc: imagePlaceholder,
      quote: ""
    },
    {
      customerName: "Koel Fresh",
      customerProfile: "",
      logoSrc: koelFresh,
      imageSrc: imagePlaceholder,
      quote: ""
    },
    {
      customerName: "Morang Chambers",
      customerProfile: "",
      logoSrc: morangChamber,
      imageSrc: imagePlaceholder,
      quote: ""
    },
  ],
  clientsHeading = "Our Esteemed Clients",
  clients = [
    {
      customerName: "Aasaman Nepal",
      customerProfile: "",
      logoSrc: aasman,
      imageSrc: imagePlaceholder,
      quote: ""
    },
    {
      customerName: "AEPC",
      customerProfile: "",
      logoSrc: aepc,
      imageSrc: imagePlaceholder,
      quote: ""
    },
    {
      customerName: "Bhawani",
      customerProfile: "",
      logoSrc: bhawani,
      imageSrc: imagePlaceholder,
      quote: ""
    },
    
    {
      customerName: "giz",
      customerProfile: "",
      logoSrc: giz,
      imageSrc: imagePlaceholder,
      quote: ""
    },
    {
      customerName: "Gorakhs",
      customerProfile: "",
      logoSrc: gorakha,
      imageSrc: imagePlaceholder,
      quote: ""
    },
    {
      customerName: "Helvetas",
      customerProfile: "",
      logoSrc: helvetas,
      imageSrc: imagePlaceholder,
      quote: ""
    },
    {
      customerName: "Mede Nepal",
      customerProfile: "",
      logoSrc: madenepal,
      imageSrc: imagePlaceholder,
      quote: ""
    },
    {
      customerName: "Martin Dairy",
      customerProfile: "",
      logoSrc: martins,
      imageSrc: imagePlaceholder,
      quote: ""
    },
    {
      customerName: "Nepal Bank Limited",
      customerProfile: "",
      logoSrc: nepalbank,
      imageSrc: imagePlaceholder,
      quote: ""
    },
    {
      customerName: "Nepal Oil Corporation Limited",
      customerProfile: "",
      logoSrc: noc,
      imageSrc: imagePlaceholder,
      quote: ""
    },
    {
      customerName: "Swiss Contact",
      customerProfile: "",
      logoSrc: swisscontact,
      imageSrc: imagePlaceholder,
      quote: ""
    },
    {
      customerName: "UNDP",
      customerProfile: "",
      logoSrc: undp,
      imageSrc: imagePlaceholder,
      quote: ""
    },
    {
      customerName: "United Cabel",
      customerProfile: "",
      logoSrc: unitedwire,
      imageSrc: imagePlaceholder,
      quote: ""
    },
    {
      customerName: "Urban",
      customerProfile: "",
      logoSrc: urban,
      imageSrc: imagePlaceholder,
      quote: ""
    },
    {
      customerName: "World Bank Group",
      customerProfile: "",
      logoSrc: worldbank,
      imageSrc: imagePlaceholder,
      quote: ""
    },
    {
      customerName: "Practical Action",
      customerProfile: "",
      logoSrc: practicalAction,
      imageSrc: imagePlaceholder,
      quote: ""
    },
  ],
  knowledgeAdvisorsHeading = "Our Knowledge Sharing Advisors",
  knowledgeAdvisors = [
    {
      customerName: "Kathmandu University",
      customerProfile: "",
      logoSrc: kathmanduUniversity,
      imageSrc: imagePlaceholder,
      quote: ""
    },
    {
      customerName: "Flensburg",
      customerProfile: "",
      logoSrc: flensburg,
      imageSrc: imagePlaceholder,
      quote: ""
    },
    {
      customerName: "IDAM Infra",
      customerProfile: "",
      logoSrc: idam,
      imageSrc: imagePlaceholder,
      quote: ""
    },
    {
      customerName: "D2O",
      customerProfile: "",
      logoSrc: d2o,
      imageSrc: imagePlaceholder,
      quote: ""
    }
  ]
}) => {
  return (
    <PrimaryBackgroundContainer>
      <Content>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
        </HeadingContainer>
        <TestimonialContainer style={{maxWidth: "46rem", marginLeft: "auto", marginRight: "auto" }}>
          <TestimonialHeader>{partnersHeading}</TestimonialHeader>
          <ClientsSlider
            arrows={false}
            infinite={true}
            pauseOnHover={false}
            slidesToShow={4}
            slidesToScroll={4}
            autoplay={true}
            speed={9000}
            autoplaySpeed={9000}
            cssEase="linear"
            variableWidth={true}
            responsive={[
              {
                breakpoint: 640,
                settings: {
                  infinite: true,
                  autoplay: true,
                  slidesToShow: 1,
                  slidesToScroll: 4
                }
              }
            ]}
          >
            {partners.map((client, index) => (
              <Client key={index}>
                <ClientLogo data-name={client.customerName} src={client.logoSrc}></ClientLogo>
              </Client>
            ))}
          </ClientsSlider>
        </TestimonialContainer>

        <TestimonialContainer>
          <TestimonialHeader>{clientsHeading}</TestimonialHeader>
          <ClientsSlider
            arrows={false}
            infinite={true}
            pauseOnHover={false}
            slidesToShow={5}
            slidesToScroll={5}
            autoplay={true}
            speed={10000}
            autoplaySpeed={10000}
            cssEase="linear"
            variableWidth={true}
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 5,
                  slidesToScroll: 10,
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 5,
                  slidesToScroll: 5,
                }
              },
              {
                breakpoint: 640,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 5
                }
              }
            ]}
          >
            {clients.map((client, index) => (
              <Client key={index}>
                <ClientLogo data-name={client.customerName} src={client.logoSrc}></ClientLogo>
              </Client>
            ))}
          </ClientsSlider>
        </TestimonialContainer>

        <TestimonialContainer style={{maxWidth: "46rem", marginLeft: "auto", marginRight: "auto" }}>
          <TestimonialHeader>{knowledgeAdvisorsHeading}</TestimonialHeader>
          <ClientsSlider
            arrows={false}
            infinite={true}
            pauseOnHover={false}
            slidesToShow={4}
            slidesToScroll={4}
            autoplay={true}
            speed={9000}
            autoplaySpeed={9000}
            cssEase="linear"
            variableWidth={true}
            responsive={[
              {
                breakpoint: 640,
                settings: {
                  infinite: true,
                  autoplay: true,
                  slidesToShow: 1,
                  slidesToScroll: 4
                }
              }
            ]}
          >
            {knowledgeAdvisors.map((client, index) => (
              <Client key={index}>
                <ClientLogo data-name={client.customerName} src={client.logoSrc}></ClientLogo>
              </Client>
            ))}
          </ClientsSlider>
        </TestimonialContainer>

      </Content>
    </PrimaryBackgroundContainer>
  );
};
