import React from "react";
import tw from "twin.macro";
import styled, { css } from "styled-components";
import { Container } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography";
import { ReactComponent as LinkedinIcon } from "images/linkedin-icon.svg";

import amrit from "images/rudk/about/teams/amrit-abhamani-dhakal.jpg";
import ayush from "images/rudk/about/teams/ayush-adhikari.jpg";
import hemanta from "images/rudk/about/teams/hemanta-bhandari.jpg";
import govinda from "images/rudk/about/teams/govinda-neupane.jpg";
import sujan from "images/rudk/about/teams/sujan-raj-bhattarai.jpg";
import pramod from "images/rudk/about/teams/pramod-adhakari.jpg";
import ankit from "images/rudk/about/teams/ankit-raghubanshi.jpg";
import muna from "images/rudk/about/teams/muna-bhattarai.jpg";
import ritu from "images/rudk/about/teams/ritu-poudyal.jpg";
import kanchan from "images/rudk/about/teams/kanchan-acharya.jpg";
import yaju from "images/rudk/about/teams/yaju-shrestha.jpg";
import sailesh from "images/rudk/about/teams/sailesh-dahal.jpg";

export const ContentWithPaddingXl= tw.div`max-w-screen-xl mx-auto pt-4 pb-8`;
const HeadingContainer = tw.div``
const Heading = tw(SectionHeading)``
const Subheading = tw(SubheadingBase)`text-center mb-3`
const Description = tw(SectionDescription)`mx-auto text-center max-w-4xl`

const Cards = tw.div`flex flex-wrap flex-row justify-center mx-auto`
const Card = tw.div`mt-24 w-full md:w-1/2 lg:w-1/3 max-w-sm sm:w-1/2 flex flex-col items-center`;
const CardsSecondary = tw(Cards)`justify-start`
const CardSecondary = tw(Card)`lg:w-1/4`;
const CardImage = styled.div`
  ${props => css`background-image: url("${props.imageSrc}"); background-size: auto;`}
  ${tw`w-64 h-64 bg-cover bg-center rounded-2xl`}
`
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6 sm:pr-6 sm:pl-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  9
`

const CardLinks = styled.div`
  ${tw`mt-2 flex`}
  .link {
    ${tw`mr-8 last:mr-0 text-gray-400 hocus:text-primary-500 transition duration-300`}
    .icon {
      ${tw`fill-current w-6 h-6`}
    }
  }
`

const CardLists = styled.div`
  ${tw`text-sm mt-2 text-gray-800 flex flex-col items-center`}
`;

const CardListItem = styled.p`
  ${tw`text-sm text-center mt-2 text-gray-800`}
`

const CardInfo = styled.p`
  ${tw`text-lg text-justify mt-2 text-gray-800`}
`;


export default ({
  heading = "Meet Our Team",
  subheading = "",
  description = "Within RUDK we have highly educated and trained industry experts across all of our divisions.",
  cards = [
    {
      imageSrc: amrit,
      position: "Managing Director",
      name: "Amrit Abhamani Dhakal",
      qualifications: [
        "12+ years of professional experience (government, private sectors, consulting (development partners) in energy and environment sector."
      ],
      // experience: '10+ years of professional experience (government, private sectors, consulting (development partners) in energy and environment sector. Hands on National and International exposure with experience of conducting Energy Management System Audit in the Netherlands and Energy Audit in about 50 enterprise in Nepal with CSR as a major climate strengthening tools.',
      links: [

        {
          url: "https://linkedin.com/in/amrit-abhamani-dhakal-17064138/",
          icon: LinkedinIcon,
        },
      ],
    },
    {
      imageSrc: ayush,
      position: "Director-Operation",
      name: "Ayush Adhikari",
      qualifications: [
        "8+ years of professional experience in renewable energy sector specialized in solar sector."
      ],
      // experience: '5+ years of professional experience in renewable energy sector specialized in solar sector. Strong Electrical Engineering professional with a Masters degree focused in Business Administration and Management, General.',
      links: [
        {
          url: "https://linkedin.com/in/ayush-adhikari-873b90137/",
          icon: LinkedinIcon,
        },
      ],
    },
    {
      imageSrc: hemanta,
      position: "Director-Technology",
      name: "Hemanta Bhandari",
      qualifications: [
        "8+ years of professional experience (government, private sectors, consulting) in renewable energy and environment sector, specifically Solar PV and hydro-power sector."
      ],
      // experience: '5+ years of professional experience (government, private sectors, consulting) in renewable energy and environment sector, specifically Solar PV and hydro- power sector. International research related to the relevant nexus interactions to the Climate, Land (Food), Energy and Water systems approach (CLEWs)',
      links: [
        {
          url: "https://linkedin.com/in/hemanta-bhandari-a5487720a/",
          icon: LinkedinIcon,
        },
      ],
    },
  ],
}) => {
  const secondaryCards = [
    {
      imageSrc: govinda,
      position: "Principle Advisor Inter-Nations USA",
      name: "Govinda Neupane",
      qualifications: [],
      links: [
        {
          url: "https://www.linkedin.com/in/govinda-neupane-35125a102/",
          icon: LinkedinIcon,
        },
      ],
    },
    {
      imageSrc: pramod,
      position: "Climate-Atmospheric Scientist",
      name: "Dr. Pramod Adhikari",
      qualifications: [],
      links: [
        {
          url: "https://www.linkedin.com/in/pramod-adhikari-ph-d-773a276a/",
          icon: LinkedinIcon,
        },
      ],
    },
    {
      imageSrc: ankit,
      position: "Strategic Business Developer - Europe",
      name: "Er. Ankit Raghubanshi",
      qualifications: [],
      links: [
        {
          url: "https://www.linkedin.com/in/ankit-raghubanshi-546017b8/",
          icon: LinkedinIcon,
        },
      ],
    },
    {
      imageSrc: sujan,
      position: "Finance officer",
      name: "Sujan Raj Bhattarai",
      qualifications: [],
      links: [
        {
          url: "https://www.linkedin.com/in/sujan-raj-bhattarai-314628166/",
          icon: LinkedinIcon,
        },
      ],
    },
    {
      imageSrc: ritu,
      position: "Advisor for Energy Climate and Development",
      name: "Ritu Poudyal",
      qualifications: [],
      links: [
        {
          url: "https://www.linkedin.com/in/ritu-poudyal-a15956141/",
          icon: LinkedinIcon,
        },
      ],
    },
    {
      imageSrc: kanchan,
      position: "Mechanical Engineer",
      name: "Er. Kanchan Acharya",
      qualifications: [],
      links: [
        {
          url: "https://www.linkedin.com/in/kanchan-acharya-3386b5179/",
          icon: LinkedinIcon,
        },
      ],
    },
    {
      imageSrc: yaju,
      position: "Mechanical Engineer",
      name: "Er. Yaju Shrestha",
      qualifications: [],
      links: [
        {
          url: "https://www.linkedin.com/in/yaju-shrestha7/",
          icon: LinkedinIcon,
        },
      ],
    },
    {
      imageSrc: sailesh,
      position: "Provincial Focal Person",
      name: "Er. Sailesh Dahal",
      qualifications: [],
      links: [
        {
          url: "https://www.linkedin.com/in/sailesh-dahal-b331b854/",
          icon: LinkedinIcon,
        },
      ],
    },
    {
      imageSrc: muna,
      position: "Environment Engineer",
      name: "Muna Bhattarai",
      qualifications: [],
      links: [
        {
          url: "https://www.linkedin.com/in/muna-bhattarai-795864267/",
          icon: LinkedinIcon,
        },
      ],
    },
  ]
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading>}
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <Cards>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} />
              <CardContent>
                <span className="position">{card.position}</span>
                <span className="name">{card.name}</span>
                <CardLinks>
                  {card.links.map((link, linkIndex) => (
                    <a key={linkIndex} className="link" href={link.url} target="_blank" rel="noreferrer">
                      <link.icon className="icon" />
                    </a>
                  ))}

                </CardLinks>
                <CardLists className="qualifications">
                    {
                      card.qualifications.map((qualification, index) => (
                        <CardListItem className="qualification" key={index}>
                          {qualification}
                        </CardListItem>
                      ))
                    }
                </CardLists>
                <CardInfo>{card.experience}</CardInfo>
              </CardContent>
            </Card>
          ))}
        </Cards>

        <CardsSecondary>
          {secondaryCards.map((card, index) => (
            <CardSecondary key={index}>
              <CardImage imageSrc={card.imageSrc} />
              <CardContent>
                <span className="position">{card.position}</span>
                <span className="name">{card.name}</span>
                <CardLinks>
                  {card.links.map((link, linkIndex) => (
                    <a key={linkIndex} className="link" href={link.url} target="_blank" rel="noreferrer">
                      <link.icon className="icon" />
                    </a>
                  ))}
                </CardLinks>
                <CardLists className="qualifications">
                    {
                      card.qualifications.map((qualification, index) => (
                        <CardListItem className="qualification" key={index}>
                          {qualification}
                        </CardListItem>
                      ))
                    }
                </CardLists>
                <CardInfo>{card.experience}</CardInfo>
              </CardContent>
            </CardSecondary>
          ))}
        </CardsSecondary>
      </ContentWithPaddingXl>
    </Container>
  );
};
