import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";

const Container = tw.div`relative max-w-screen-xl mx-auto`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TextColumn = styled(Column)(props => [
  tw`mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Heading = tw(
  SectionHeading
)`mt-4 font-black text-3xl sm:text-4xl lg:text-5xl text-center leading-tight`;

const Steps = tw.ul`mt-12 px-4`;
const Step = tw.li`mt-8 flex flex-col md:flex-row items-center md:items-start`;
const StepNumber = tw.div`font-semibold text-xl leading-none text-primary-500`;
const StepText = tw.div`mt-3 md:mt-0 md:ml-6`;
const StepHeading = tw.h6`leading-none text-xl font-bold m-0 text-primary-500`;
const StepDescription = tw.p`mt-3 leading-loose text-sm text-gray-600 font-medium`;

export default ({
  heading = "Our research activities",
  textOnLeft = true,
  steps = null,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const defaultSteps = [
    {
      heading: "Possibility of fuel switching scenarios (LPG/Firewood to clean fuel (pellets and electricity)) in MSMEs in some regions of Nepal.",
      description: "This study focuses on the potential of replacing LPG stoves with bio pellet stoves in highway hotels around Ramnagar, Chitwan. Through a questionnaire survey and Indoor Air Pollution (IAP) monitoring, the study assessed current energy use and emissions from LPG and bio pellet stoves. Results show that while LPG and wood are prevalent, bio pellet stoves offer significant energy savings and lower emissions. The study highlights the economic and environmental benefits of bio pellets, offering a model for energy transition in Nepal, and serving as a potential blueprint for similar initiatives. This study was conducted as a thesis by Research Associate conducting his Master in Planning and Operations of Energy Systems under School of Engineering in Kathmandu University."
    },
    {
      heading: "Research in Climate, Land, Energy and Water (CLEWs) interconnection for sustainable development.",
      description: "This study introduces the amazing CLEWs Nepal model - a powerful tool that sheds light on the intricate interplay between climate policies and crucial resources like land, energy, and water. With more and more countries taking bold steps to combat climate change, it's crucial to understand the ripple effects of such decisions on other sectors. That's where CLEWs come in, providing decision-makers with valuable insights into the trade-offs and synergies involved in various climate policies.",
      link: 'https://nepjol.info/index.php/JACEM/article/view/55921/41794'
    },
    {
      heading: "Evaluation of determining factors for climate resilient solar irrigation farming in Nepal; A pathway towards 100% RE",
      description: "This research investigates the sustainability of Solar Irrigation Pumps (SIPs) in Nepal's Sarlahi and Rautahat districts, focusing on social, environmental, and economic dimensions. Using online and physical surveys, the study incorporates the Analytical Hierarchy Process (AHP) model to prioritize cost minimization and profit maximization, highlighting the significance of carbon emissions. Divergent stakeholder perspectives underscore the need for targeted outreach and cohesive understanding of SIP sustainability. The study recommends promoting green technologies, exploring carbon credits, and integrating water conservation practices to enhance SIP sustainability in line with Nepal's renewable energy goals."
    },
    {
      heading: "Comprehensive Research Initiative on Sustainable Building Certification for Nepal",
      description: "Rastriya Urja Dakshata Kendra Pvt. Ltd. is actively engaged in advancing sustainable practices through rigorous energy and resource efficiency audits in commercial buildings, aiming for certifications like LEED, GRIHA, and EDGE. Our ongoing research, conducted in collaboration with Indian experts, seeks to develop a region-specific building certification system tailored to Nepal's unique environmental and socio-economic conditions. This initiative is driven by the need for localized standards that promote sustainability, reduce carbon footprints, and enhance energy efficiency within the country's rapidly growing commercial infrastructure."
    },
    {
      heading: "Research on Fuel Switching Scenarios: Transitioning from IC Engines to EV",
      description: "Rastriya Urja Dakshata Kendra Pvt. Ltd. is conducting research into possible fuel-switching scenarios, focusing on the transition from internal combustion (IC) engines to electric vehicles. This research aims to identify the most effective strategies for replacing traditional fossil fuel-driven engines with clean, efficient electric vehicles. By analyzing various technological, economic, and environmental factors, we seek to facilitate a smooth and sustainable transition to EVs, ultimately reducing greenhouse gas emissions and supporting the global shift toward a greener, more energy-efficient transportation system."
    },
    {
      heading: "Study on sustainable heating approaches in the case of Nepal",
      description: "Rastriya Urja Dakshata Kendra Pvt. Ltd. in collaboration with Europa University Flensburg, Germany, and the Energy Systems and Technology Research Laboratory (ESTRL) at Kathmandu University, is exploring sustainable heating approaches suitable for the higher altitudes of Nepal, where harsh climates and limited energy access pose significant challenges. The research aims to map current heating practices and assess the viability of alternative heating technologies. By comparing various heating solutions, the study seeks to identify technologies that are both environmentally sustainable and economically feasible for deployment in Nepal's mountainous regions. The findings will contribute to the development of tailored heating strategies that reduce environmental impact while ensuring thermal comfort for communities living in higher altitudes."
    },
    {
      heading: "Evaluating the Efficiency of Traditional Cardamom Drying Practices in Koshi Province, Nepal",
      description: "This research investigates the efficiency and environmental performance of the traditional Bhatti-drying process for large cardamoms in Koshi Province, Nepal. The study focuses on evaluating the current drying practices, particularly the efficiency, fuelwood consumption, and emissions associated with the conventional drying method. Additionally, the research assesses the quality of cardamom produced using this traditional technique. Recognizing the environmental and economic challenges posed by the existing method, this study also explores alternative drying techniques that could be implemented in Nepal. The ultimate goal is to identify drying methods that maintain or improve product quality while reducing environmental impact, thus offering sustainable solutions for the cardamom industry in Nepal. The findings of this research are expected to contribute to more efficient and eco-friendly practices in the region’s cardamom drying processes."
    },
    {
      heading: "Fuel switching from Burnt engine oil and diesel to bio pellets in different Dalmoth and Carpet factories of Kathmandu",
      description: "This study examines the potential of fuel switching from burnt engine oil and diesel to bio pellets in Dalmoth and carpet factories in Kathmandu. Through a detailed analysis of the environmental, economic, and operational impacts, the study evaluates the feasibility and benefits of adopting bio pellets as an alternative fuel source. The research employs both quantitative and qualitative methods, including surveys and energy efficiency assessments, to gauge the performance and sustainability of bio pellets compared to conventional fuels. Key findings highlight the reduction in carbon emissions and cost savings, emphasizing the importance of transitioning to cleaner energy sources in these industries"
    }
  ];

  if (!steps) steps = defaultSteps;

  return (
    <Container>
      <TextColumn textOnLeft={textOnLeft}>
        <TextContent>
          <Heading>{heading}</Heading>
          <Steps>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepNumber>{(index + 1).toString().padStart(2, '0')}</StepNumber>
                <StepText>
                  <StepHeading>{step.heading}</StepHeading>
                  <StepDescription>{step.description}</StepDescription>
                </StepText>
              </Step>
            ))}
          </Steps>
        </TextContent>
      </TextColumn>
    </Container>
  );
};
