import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import HeaderBase from "components/headers/light.js"
import Footer from "components/footers/Footer.js";

import { NavLinksComponent } from "helpers/NavLinks";
import { ROUTES } from "../constants";

import backgroundImage from "images/rudk/research/hero-background.jpg";
import BackgroundAsImageWithCenteredContent from "components/hero/BackgroundAsImageWithCenteredContent";
import ProjectsTable from "components/tables/ProjectsTable";
import List from "components/features/List";
import Trainings from "components/features/Trainings";
import { PrimaryText } from "components/misc/Typography";
import useScrollToHash from "helpers/useScrollToHash";

const Header = tw(HeaderBase)`max-w-none`;
const Section = tw.section``;

const projects = [
  {
    "id": "1",
    "assignment": 'Thermal and electrical Course Development for "Energy Efficiency Training (Thermal and Electrical)" for Technicians of Manufacturing Industries in Bagmati Province and all over Country',
    "location": "Kathmandu",
    "client": "ENSSURE, Helvetas Nepal"
  },
  {
    "id": "2",
    "assignment": "Energy Efficiency Training ( Electrical) for Technicians of Manufacturing Industries in Bagmati Province",
    "location": "Chitwan",
    "client": "ENSSURE, Helvetas Nepal"
  },
  {
    "id": "3",
    "assignment": "Solar Pumping System Operator Training to Selected Participants of Madhesh Province",
    "location": "Janakpur, Dhanusha",
    "client": "RERL, UNDP"
  },
  {
    "id": "4",
    "assignment": "Solar Pumping System Operator Training to Selected Participants of Karnali Province",
    "location": "Surkhet, Karnali Province",
    "client": "RERL, UNDP"
  },
  {
    "id": "5",
    "assignment": "Solar Pumping System Design and Installation Training to Department of Drinking Water Supply and Sewerage Management",
    "location": "Janakpur, Dhanusha",
    "client": "RERL, UNDP"
  },
  {
    "id": "6",
    "assignment": "Solar Pumping System Operator Training",
    "location": "Lalbandi, Sarlahi",
    "client": "RERL, UNDP"
  },
];

export default () => {
  useScrollToHash();

  return (
    <AnimationRevealPage disabled>
      <Header links={
        <NavLinksComponent
          activeLink={ROUTES.RESEARCH}
        />
      } />
      <Section id="overview">
        <BackgroundAsImageWithCenteredContent
          heading="Research"
          backgroundImage={backgroundImage}
          description={
            <>
              Rastriya Urja Dakshata Kendra Pvt. Ltd. is proud to have a team of exceptionally qualified researchers engaged in diverse studies both nationally and internationally. Our researchers are working on climate and renewable energy research on the global stage, with many also involved in academic pursuits. We foster strong collaborations with leading universities to identify and pursue research topics that drive national development.
            </>
          }
          hero={true}
        />
      </Section>
      <Section id="research">
        <List />
      </Section>
      <Section id="training">
        <Trainings
          heading="Training"
          description1="Technology transfer, coupled with workforce education, is crucial for sustainable development and growth in any sector. By imparting knowledge and skills through various trainings nationwide, Rastriya Urja Dakshata Kendra Pvt. Ltd. not only advances technology but also ensures that individuals are equipped to leverage and apply these advancements effectively. This approach not only benefits your organization but also contributes significantly to the overall development of the nation's workforce and economy."
          description2="We have been involved in various trainings nationwide to increase awareness of renewable energy and energy efficiency."
        />
      </Section>
      <Section id="projects">
        <ProjectsTable
          title="Some of our Trainings/knowledge transfer"
          description=""
          data={projects}
        />
      </Section>
      <Footer />
    </AnimationRevealPage>
  );
};
