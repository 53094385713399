import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import { SectionDescription } from "components/misc/Typography.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import defaultCardImage from "images/shield-icon.svg";
import Heat from "images/rudk/our-expertise/heat.jpg";
import Solar from "images/rudk/our-expertise/solar.jpg";
import capacityDevelopment from "images/rudk/our-expertise/capacity-development.jpeg";
import substainableFinancing from "images/rudk/our-expertise/sustainable-financing.jpg";
import research from "images/rudk/our-expertise/research.jpg";
import climate from "images/rudk/our-expertise/climate.jpeg";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-evenly max-w-screen-2xl mx-auto`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center max-w-3xl`;

const VerticalSpacer = tw.div`mt-2 w-full`

const Column = styled.div`
  ${tw`w-full md:w-1/2 lg:w-1/3 max-w-sm`}
`;
const Image = styled(motion.div)(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-64 bg-cover bg-center rounded-2xl`
]);

const Card = styled(motion.div)`
  ${tw`flex flex-col cursor-pointer items-center sm:items-start text-center sm:text-left h-full px-2 py-4`}
  .textContainer {
    ${tw`mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const CardTitle = tw.span`transition duration-300 group-hover:text-primary-500`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null, heading = "Amazing Features", subheading = "Features", description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." }) => {
  const navigate = useNavigate();
  const defaultCards = [
    { // 1
      imageSrc: Heat,
      title: "Energy Efficiency",
      linkTo: "/energy-efficiency",
      // description: "Advise public and private sector clients in the development and implementation of regional, national and international strategies. In this, we build on our long-standing experience in all relevant fields of action within the energy transition."
    },
    { // 2
      imageSrc: Solar,
      title: "Energy Project Development",
      // description: "We develop and manage projects on behalf of our clients, We trust in high quality, promote knowledge networks and use the experience acquired to develop more advanced solutions and strategies."
    },
    { // 3
      imageSrc: research,
      title: "Research, Innovation & Data Center",
      linkTo: "/research",
      // . description: "We initiate, design and moderate a variety of dialogue formats at the interface between business and development. Here we can build on our cross-sectoral network that has matured over many years. Addressing different communicative challenges and move towards digitalisation of energy."
    },
    { // 4
      imageSrc: climate,
      title: "Climate Change Resilience & Sustainability",
      linkTo: "/climate",
      // . description: "To develop quality standards that we establish in a practical setting. In doing so, we create transparency, communicate knowledge among the key actors and foster the development of suitable conditions. We therefore bring growth to the markets and progress in the energy transition."
    },
    { // 5
      imageSrc: capacityDevelopment,
      title: "Capacity Development & Training",
      linkTo: "/research#training",
      // . description: "We initiate, design and moderate a variety of dialogue formats at the interface between business and development. Here we can build on our cross-sectoral network that has matured over many years. Addressing different communicative challenges and move towards digitalisation of energy."
    },
    { // 6
      imageSrc: substainableFinancing,
      title: "Sustainable Financing",
      linkTo: "/climate#greenFinance",
      // . description: "We initiate, design and moderate a variety of dialogue formats at the interface between business and development. Here we can build on our cross-sectoral network that has matured over many years. Addressing different communicative challenges and move towards digitalisation of energy."
    },
  ];

  const cardAnimation = {
    rest: {
      backgroundSize: "100%"
    },
    hover: {
      backgroundSize: "120%"
    }
  };

  if (!cards) cards = defaultCards;

  const onCardClick = (card) => {
    if (card.linkTo) {
      navigate(card.linkTo, { preventScrollReset: false });
    }
  }

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card onClick={() => onCardClick(card)} className="group" initial="rest" whileHover="hover" animate="rest">
              <Image
                transition={{ duration: 0.3 }}
                variants={cardAnimation}
                imageSrc={card.imageSrc}
              />
              <CardTitle className="title">{card.title}</CardTitle>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
