import React from "react";

import Routers from "./Routers";

import "styles/globalStyles.css";

export default function App() {
  return (
    <Routers />
  );
}
