import { motion, useInView } from "framer-motion";
import { useRef } from "react";

const AnimatedContainer = ({ direction, once = false, children, initialOffsetX, initialOffsetY }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once });

  const x = { target: "0%" };
  const y = { target: "0%" };

  if (direction === "top") y.initial = initialOffsetY || "-10%";
  if (direction === "bottom") y.initial = initialOffsetY || "10%"
  if (direction === "left") x.initial = initialOffsetX || "-10%";
  if (direction === "right") x.initial = initialOffsetX || "10%"

  return (
    <motion.div
      initial={{ x: x.initial, y: y.initial }}
      animate={{
        x: isInView && x.target,
        y: isInView && y.target,
        transitionEnd: {
          x: isInView && 0,
          y: isInView && 0
        }
      }}
      transition={{ type: "spring", damping: 19 }}
      ref={ref}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedContainer;