import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import HeaderBase from "components/headers/light.js"
import Footer from "components/footers/Footer.js";

import { NavLinksComponent } from "helpers/NavLinks";
import { ROUTES } from "../constants";

const Header = tw(HeaderBase)`max-w-none`;

export default () => {
  return (
    <AnimationRevealPage disabled>
      <Header links={
        <NavLinksComponent
          activeLink={ROUTES.RENEWABLE_ENERGY}
        />
      } />
      Content of the page
      <Footer />
    </AnimationRevealPage>
  );
};
