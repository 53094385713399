import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import HeaderBase from "components/headers/light.js";
import Footer from "components/footers/Footer.js";
import ContactUsForm from "components/forms/ContactUsForm";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import { ROUTES } from "../constants";
import { NavLinksComponent } from "helpers/NavLinks";
import BackgroundAsImageWithCenteredContent from "components/hero/BackgroundAsImageWithCenteredContent";

import backgroundImage from "images/rudk/contact/hero-background.jpg";
import BgHeroSmall from "components/hero/BgHeroSmall";

const Header = tw(HeaderBase)`max-w-none`;
const Section = tw.section``;
const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header links={
        <NavLinksComponent
          activeLink={ROUTES.CONTACT_US}
        />
      } />
      <Section id="overview">
        <BgHeroSmall
          heading="Contact Us"
          backgroundImage={backgroundImage}
          description=""
          hero={true}
        />
      </Section>
      <ContactUsForm />
      <ContactDetails
        description=""
        cards={[
          {
            title: "Kathmandu, Nepal",
            description: (
              <>
                <Address>
                  <AddressLine>Basundhara-3, Kathmandu</AddressLine>
                  <AddressLine>Nepal 44600</AddressLine>
                </Address>
                <Email>info@nationaleecenter.com</Email>
                <Phone>+977 984-3305814</Phone>
                <Phone>+977 985-1135107</Phone>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3531.3027770221715!2d85.33072527660771!3d27.738805724101127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1968fa993c77%3A0x92a3b419a252047!2sRastriya%20Urja%20Dakshata%20Kendra!5e0!3m2!1sen!2snp!4v1722503131901!5m2!1sen!2snp"
                  width="600"
                  height="450"
                  style={{ border: '0', marginTop: '3rem'}}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </>
            )
          },
          {
            title: "Biratnagar, Nepal",
            description: (
              <>
              <Address>
                  <AddressLine>Energy Efficiency, Safety and Environment Cell(EESEC),</AddressLine>
                  <AddressLine>Chamber of Industries Morang,</AddressLine>
                  <AddressLine>Tinpaini, Biratnagar 56613</AddressLine>
                </Address>
                <Email>info@nationaleecenter.com</Email>
                <Phone>+977 980-2368631</Phone>
              </>
              
            )
          },
          {
            title: "United States",
            description: (
              <>
                <Address>
                  <AddressLine>41 Gage St #2</AddressLine>
                  <AddressLine>Augusta, ME 04330, USA</AddressLine>
                </Address>
                <Email>info@nationaleecenter.com</Email>
              </>
            )
          }
          /* {
            title: "Illinois",
            description: (
              <>
                <Address>
                  <AddressLine>602 Annadale Drive</AddressLine>
                  <AddressLine>Dekalb, IL 60115</AddressLine>
                </Address>
                <Email>contact@treact.com</Email>
                <Phone>+1 (203) 991-6988</Phone>
              </>
            )
          },
          {
            title: "California",
            description: (
              <>
                <Address>
                  <AddressLine>96 NE. Delaware Lane</AddressLine>
                  <AddressLine>Sacramento, CA 95820</AddressLine>
                </Address>
                <Email>contact@treact.com</Email>
                <Phone>+1 (203) 991-6988</Phone>
              </>
            )
          },
          {
            title: "Tennessee",
            description: (
              <>
                <Address>
                  <AddressLine>74 Peachtree Ave.</AddressLine>
                  <AddressLine>Dyersburg, TN 38024</AddressLine>
                </Address>
                <Email>contact@treact.com</Email>
                <Phone>+1 (203) 991-6988</Phone>
              </>
            )
          },
          {
            title: "New Jersey",
            description: (
              <>
                <Address>
                  <AddressLine>8355 Summer Street</AddressLine>
                  <AddressLine>Manchester, NJ 08759</AddressLine>
                </Address>
                <Email>contact@treact.com</Email>
                <Phone>+1 (203) 991-6988</Phone>
              </>
            )
          },
          {
            title: "Ohio",
            description: (
              <>
                <Address>
                  <AddressLine>7713 Snake Hill Ave.</AddressLine>
                  <AddressLine>Piqua, OH 45356</AddressLine>
                </Address>
                <Email>contact@treact.com</Email>
                <Phone>+1 (203) 991-6988</Phone>
              </>
            )
          } */
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
