import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import HeaderBase from "components/headers/light.js";
import Footer from "components/footers/Footer.js";
import GetStarted from 'components/cta/GetStarted.js'
import { NavLinksComponent } from "helpers/NavLinks";
import { ROUTES } from "../constants";

const Header = tw(HeaderBase)`max-w-none`;

export default () => {
  return (
    <AnimationRevealPage disabled>
      <Header links={
        <NavLinksComponent
          activeLink={ROUTES.CAREERS}
        />
      } />
      <GetStarted
        text={<>Please drop your resume at <i><a href="mailto:info@nationaleecenter.com">info@nationaleecenter.com</a></i>. We will reach out whenever suitable position is vacant.</>}
        hideLinks={true}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
